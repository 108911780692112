import React from "react";

import Layout from "components/layout";
import MyBronTickets from "modules/my-bron-tickets/VoidTickets";

const MyBronTicketsPage = () => (
    <Layout>
        <MyBronTickets/>
    </Layout>
)

export default MyBronTicketsPage