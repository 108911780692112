import React, {Fragment, useCallback, useState} from 'react';

import AlertComponent from 'components/alert';
import RenderIf from "components/RenderIf";
import CustomProgress from 'modules/auth/components/CustomProgress';
import {Helmet} from 'react-helmet-async';

import {PlayCirculation} from "../components/PlayCirculation";
import useCirculation from '../hooks/useCirculation';
import BarcodeModal from "../modals/Barcode";
import '../styles/style.css';

export default function PlayList() {

    const {data, loading, refetch} = useCirculation();

    const [showBarcode, setShowBarcode] = useState(false);
    const [ticketInformation, setTicketInformation] = useState({});

    const onCreateVoidTicket = useCallback((ticketInformation) => {
        setTicketInformation(ticketInformation);
        setShowBarcode(true);
    }, [])

    return (
        <Fragment>
            <Helmet>
                <title>Bron et | 1x2</title>
                <meta name="description" content="Bron et | 1x2"/>
            </Helmet>
            <div className="play-list bg-gray py-4">
                <div className="container play-list-container">
                    {data?.length ? (
                        <PlayCirculation
                            circulations={data}
                            onCreateVoidTicket={onCreateVoidTicket}
                            refetch={refetch}
                        />
                    ) : !data.length && !loading ? (
                        <AlertComponent
                            variant="info"
                            message="Hazırda aktiv tur yoxdur."
                        />
                    ) : (
                        ''
                    )}
                    {loading && (
                        <div className="custom-progress">
                            <CustomProgress/>
                        </div>
                    )}
                </div>
            </div>
            <RenderIf condition={showBarcode}>
                <BarcodeModal
                    show={showBarcode}
                    onHide={setShowBarcode}
                    ticketInformation={ticketInformation}
                    url="voidTickets"
                    enablePrint
                />
            </RenderIf>
        </Fragment>
    );
}
