import React from 'react';

import { CircularProgress } from '@mui/material';
import {createUseStyles} from 'react-jss'
import './style/style.css';

const useStyles = createUseStyles({
  root: {
    color: 'red !important',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) !important',
  },
});

export default function CustomProgress() {
  const classes = useStyles();

  return <CircularProgress className={classes.root} />;
}
