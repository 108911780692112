import React, { useEffect, useState } from 'react';

import axios from 'axios';
import DateTimePicker from 'components/DatePicker';
import { Trophy } from 'components/icons';
import LottiePlayer from 'components/lottie-player';
import diceAnimation from 'components/lottie-player/animations/dice-animation.json';
import RenderIf from 'components/RenderIf';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';

import './result.css';

const dateObject = new Date();
const year = dateObject.getFullYear();
const month =
  dateObject.getMonth() + 1 < 10
    ? `0${dateObject.getMonth() + 1}`
    : dateObject.getMonth() + 1;
const day = dateObject.getDate();
const initialDate = `${year}-${month}-${day}`;

function getUTCTimestamp(initialDate) {
  const date = new Date(initialDate);
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );
}

function getWinnerData(result) {
  if (result.winnerId === result.black.id) {
    return result.black;
  }
  return result.white;
}

export default function Results() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(initialDate);

  const getResults = () => {
    setIsLoading(true);
    const day = getUTCTimestamp(date);
    axios
      .get(`https://api.nards.club/api/nards/site/results?day=${day}`)
      .then(({ data }) => {
        setData(data?.data ?? []);
      })
      .catch(() => null)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getResults();
  }, [date]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Nəticələr | Nard</title>
        <meta name="description" content="Ana səhifə | Nard" />
      </Helmet>
      <div className="results">
        <div className="results__container px-2 px-sm-4">
          <div className="results__header">
            <span className="results__title">Nəticələr</span>
            <DateTimePicker
              value={dayjs(date)}
              onChange={(value) => {
                setDate(value);
              }}
            />
          </div>
          <div className="results__table">
            {!isLoading ? (
              data.map((row, index) => (
                <div key={index} className="results__row">
                  <div className="results__match">
                    <div className="results__card">
                      <div className="results__card__caption">
                        <p>{row.black?.surname}</p>
                        <p>{`${row.black?.name} ${row.black?.patronymic}`}</p>
                      </div>
                      <div className="results__image">
                        <img
                          src={`https://www.nards.club${row.black?.avatar}`}
                          alt={row.black?.name}
                        />
                        <RenderIf condition={row.winnerId === row.black?.id}>
                          <div className="results__trophy d-xl-none">
                            <Trophy />
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                    <div className="results__versus">
                      <p>TN{row.id}</p>
                      <p>vs</p>
                    </div>
                    <div className="results__card">
                      <div className="results__image">
                        <img
                          src={`https://www.nards.club${row.white?.avatar}`}
                          alt={row.white?.name}
                        />
                        <RenderIf condition={row.winnerId === row.white?.id}>
                          <div className="results__trophy d-xl-none">
                            <Trophy />
                          </div>
                        </RenderIf>
                      </div>
                      <div className="results__card__caption">
                        <p>{row.white?.surname}</p>
                        <p>{`${row.white?.name} ${row.white?.patronymic}`}</p>
                      </div>
                    </div>
                    <div className="results__trophy__container">
                      <div className="results__trophy">
                        <Trophy />
                      </div>
                      <p className="results__trophy__text">
                        {getWinnerData(row)?.surname}
                      </p>
                      <p className="results__trophy__text">{`${
                        getWinnerData(row)?.name
                      } ${getWinnerData(row)?.patronymic}`}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <LottiePlayer
                animation={diceAnimation}
                width="200px"
                height="200px"
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
