import React, {Fragment, useEffect, useState} from "react";

import {PDF} from "components/icons";
import RenderIf from "components/RenderIf";
import {Helmet} from "react-helmet-async";

import "./Documents.css";
import {getTournaments} from "./requests";

const documents = [
    {
        id: 1,
        title: "Nərd idmanının qaydaları",
        link: "/pdf/rule_nards.pdf",
        size: "5.4MB"
    },
    {
        id: 2,
        title: "Qanunvericilik bazası",
        link: "/pdf/physical_culture.pdf",
        size: "1.9MB"
    },
    {
        id: 3,
        title: "Partnyor şirkətin nizamnaməsi",
        link: "/pdf/company_rules.pdf",
        size: "4.8MB"
    },
];

const nardDocuments = [
    {
        id: 1,
        title: "Protokol",
        link: "https://api.nards.club/documents/protocol_NC",
        size: "40KB"
    },
    {
        id: 2,
        title: "Qaydalar",
        link: "https://api.nards.club/documents/regulation_NC",
        size: "86KB"
    },
    {
        id: 3,
        title: "Situasiya",
        link: "https://api.nards.club/documents/situation_NC",
        size: "112KB"
    },
];

const Documents = () => {
    const [limit, setLimit] = useState(10);
    const [data, setData] = useState({tournaments: [], more: false});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        getTournaments(limit)
            .then(({data}) => {
                setData(data?.data)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [limit])

    return (
        <Fragment>
            <Helmet>
                <title>Sənədlər | Nard</title>
                <meta name="description" content="Partnyorlar | Nard"/>
            </Helmet>
            <div className="documents">
                <div className="documents__container px-2 px-sm-4">
                    <h1 className="documents__title">Sənədlər</h1>
                    <div className="documents__list pb-5">
                        {documents.map(document => (
                            <a key={document.id} href={document.link} target="_blank" rel="noreferrer"
                               className="documents__item">
                                <PDF/>
                                <div className="documents__item-caption">
                                    <span>{document.title}</span>
                                    <div className="documents__item-inner">
                                        <span className="documents__item-download">yüklə</span>
                                        <span>{document.size}</span>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                    <h1 className="documents__title">Turnirlər haqqında sənədlər</h1>
                    {data.tournaments?.map(tournament => (
                        <div key={tournament.id} className="pb-5">
                            <h1 className="documents__title-secondary">№ {tournament.id}</h1>
                            <div className="documents__list">
                                {nardDocuments.map(document => (
                                    <a key={document.id} href={`${document.link}${tournament.id}.pdf`} target="_blank"
                                       rel="noreferrer"
                                       className="documents__item">
                                        <PDF/>
                                        <div className="documents__item-caption">
                                            <span>{document.title}</span>
                                            <div className="documents__item-inner">
                                                <span className="documents__item-download">yüklə</span>
                                                <span>{document.size}</span>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    ))}
                    <RenderIf condition={data.more}>
                        <button
                            onClick={() => setLimit(prevState => prevState + 10)}
                            className="documents__load-more"
                            disabled={isLoading}
                        >
                            Daha çox yüklə
                        </button>
                    </RenderIf>
                </div>

            </div>
        </Fragment>
    )
}

export default Documents;