import axios from "axios";

const GET_TOKEN_URL = `https://api.nards.club/api/nards/site/broadcast/token?role=audience&room=room_1&type=livekit`;

const SERVER_URL = 'wss://stream-balancer.nards.club/livekit-server';

const getToken = (uid) => (
    axios.get(GET_TOKEN_URL, {params: {uid}})
)

export {getToken, SERVER_URL}