import HomeLayout from 'components/layout/HomeLayout';

import {WinHistory} from "../modules/win-history";

const WinHistoryPage = () => (
    <HomeLayout>
        <WinHistory />
    </HomeLayout>
);

export default WinHistoryPage;
