import React from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import Alert from 'react-bootstrap/Alert';
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
  alertRoot: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 8px 32px 8px',
  },
  alert: {
    width: '100%',
    maxWidth: '360px',
    display: 'flex',
    padding: '12px',
    background: 'rgb(255, 0, 9)',
    color: '#ffffff',
  },
  icon: {
    fontSize: '25px',
    flexShrink: 0,
  },
  message: {
    paddingLeft: '4px',
    display: 'flex',
    alignItems: 'center',
  },
});

export default function AlertComponent({ variant, message }) {
  const classes = useStyles();
  return (
    <div className={classes.alertRoot}>
      <Alert variant={variant} className={classes.alert}>
        {variant === 'info' ? (
          <InfoIcon className={classes.icon} />
        ) : (
          <ErrorIcon />
        )}
        <span className={classes.message}>{message}</span>
      </Alert>
    </div>
  );
}
