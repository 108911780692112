import React from "react";

import "./PrimaryButton.css";

const Button = ({children, className = '', variant, ...rest}) => {
    return (
        <button className={`primary-button ${className} ${variant === 'secondary' ? 'primary-button-outline' : ''}`} {...rest}>{children}</button>
    )
}

export default Button