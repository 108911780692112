import {Link} from 'react-router-dom';

export const GoMyTickets = () => (
  <div>
    <h2 className="mb-2">Bron et kuponu uğurla yaradıldı!</h2>
    <Link to="/my-bron-tickets">"Bron edilmiş kuponlara keçid et.</Link>
  </div>
);

export const InfoToast = () => (
  <div>
    <h2>Turdakı bütün oyunlar üçün proqnoz verilməlidir.</h2>
  </div>
);

export const ErrToast = () => (
  <div>
    <h2>Tur artıq başlayıb.Əməliyyat uğursuzdur.</h2>
  </div>
);
