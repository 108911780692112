import { useState, useEffect } from 'react';

import { otp } from 'modules/auth/_redux/authCrud';
import { useSelector } from 'react-redux';

export default function useOtpTimer(initialTime = 30) {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [isFinished, setIsFinished] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState('');

  const number = useSelector(({ auth }) =>
    auth?.phoneNumber?.replace(/[()+\s-]/g, '')
  );

  async function resetTimer() {
    setIsDisabled(true);
    try {
      await otp(number);
      setTimeLeft(initialTime);
      setIsFinished(false);
      setIsDisabled(false);
    } catch (err) {
      setIsDisabled(false);
      setError(err.response?.data?.message ?? 'Xəta baş verdi');
    }
  }

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsFinished(true);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (timeLeft % 60).toString().padStart(2, '0');
  const formattedTime = `${minutes}:${seconds}`;

  return { time: formattedTime, isFinished, isDisabled, resendOtp: resetTimer, error };
}
