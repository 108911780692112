import React from "react";

import Layout from "components/layout/BronLayout";
import BronUpdate from "modules/bron/BronUpdate";

const BronUpdatePage = () => (
    <Layout>
        <BronUpdate/>
    </Layout>
)

export default BronUpdatePage