import {
  Crocusoft,
  Email,
  Facebook,
  Instagram,
  LegalAge,
  Phone,
  Tiktok,
  Whatsapp,
} from 'components/icons';
import { Link } from 'react-router-dom';

import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container footer__container">
        <div className="footer__logo">
          <img src="/media/topaz-nard-logo.svg" alt="Topaz Nard" />
        </div>
        <div className="footer__links">
          <p className="footer__text">Keçidlər</p>
          <ul>
            <li>
              <Link to="/" className="footer__text--gray">
                Ana səhifə
              </Link>
            </li>
            <li>
              <Link to="/results" className="footer__text--gray">
                Nəticələr
              </Link>
            </li>
            <li>
              <Link to="/sportsmen" className="footer__text--gray">
                İdmançılar
              </Link>
            </li>
            {/* <li>
              <Link to="/documents" className="footer__text--gray">
                Sənədlər
              </Link>
            </li> */}
            <li>
              <Link to="/partners" className="footer__text--gray">
                Partnyorlar
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer__contact">
          <p className="footer__text">Əlaqə</p>
          <ul>
            <li>
              <a href="tel: 926" className="footer__text--gray">
                <Phone />
                926
              </a>
            </li>
            <li>
              <a href="mailto: help@topaz.az" className="footer__text--gray">
                <Email />
                help@topaz.az
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/994512147448"
                rel="noreferrer"
                target="_blank"
                className="footer__text--gray"
              >
                <Whatsapp />
              </a>
              <a
                href="https://www.tiktok.com/@topaznard"
                rel="noreferrer"
                target="_blank"
                className="footer__text--gray"
              >
                <Tiktok />
              </a>
              <a
                href="https://www.instagram.com/topaznard.az"
                rel="noreferrer"
                target="_blank"
                className="footer__text--gray"
              >
                <Instagram />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61569569710929"
                rel="noreferrer"
                target="_blank"
                className="footer__text--gray"
              >
                <Facebook />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__legal-age">
          <div className="footer__legal-age-inner">
            <LegalAge />
            <p>
              18 yaşından kiçik şəxslərin idman mərc oyunlarında iştirak etməsi
              qadağandır!
            </p>
          </div>
          <div className="footer__legal-age-bottom">
            <p>Developed by</p>
            <div className="footer__legal-age-bottom-flex">
              <a href="https://crocusoft.com" target="_blank" rel="noreferrer">
                <Crocusoft className="footer__crocusoft" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <p>&copy; Topaz – All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
