import React from 'react';

import {PatternFormat} from 'react-number-format';

import "./PhoneInput.css";

const PhoneInput = ({className = '', ...props}) => {
    return (
        <PatternFormat
            className={`phone-input ${className}`}
            format="(+994)##-###-##-##"
            placeholder="(+994)_ _-_ _ _-_ _-_ _"
            allowEmptyFormatting
            mask="_"
            {...props}
        />
    );
};

export default PhoneInput;