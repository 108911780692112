import React from "react";

function View() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            fill="none"
            viewBox="0 0 17 18"
        >
            <g>
                <path
                    fill="#00AFF5"
                    d="M8.365 11.474c.783 0 1.449-.274 1.996-.823a2.723 2.723 0 00.821-1.998c0-.784-.274-1.45-.822-1.997a2.723 2.723 0 00-1.999-.82c-.783 0-1.449.273-1.996.822a2.723 2.723 0 00-.821 1.998c0 .784.274 1.45.822 1.997a2.723 2.723 0 001.999.82zm-.002-.952a1.8 1.8 0 01-1.323-.545 1.8 1.8 0 01-.544-1.322c0-.52.181-.96.544-1.323a1.8 1.8 0 011.323-.545 1.8 1.8 0 011.323.545 1.8 1.8 0 01.544 1.323 1.8 1.8 0 01-.544 1.322 1.8 1.8 0 01-1.323.545zm0 2.973c-1.59 0-3.038-.438-4.346-1.316a7.77 7.77 0 01-2.889-3.524A7.773 7.773 0 014.016 5.13a7.63 7.63 0 014.346-1.316c1.59 0 3.04.438 4.347 1.316a7.77 7.77 0 012.889 3.525 7.773 7.773 0 01-2.888 3.524 7.63 7.63 0 01-4.346 1.316zm0-1.037a6.6 6.6 0 003.587-1.029 6.758 6.758 0 002.499-2.774A6.759 6.759 0 0011.95 5.88 6.6 6.6 0 008.363 4.85 6.6 6.6 0 004.776 5.88a6.758 6.758 0 00-2.499 2.775 6.758 6.758 0 002.499 2.774 6.6 6.6 0 003.587 1.029z"
                ></path>
            </g>
        </svg>
    );
}

export default View;