import React from "react";

import Layout from "components/layout";

import MyTickets from "../modules/my-tickets/MyTickets";

const MyTicketsPage = () => (
    <Layout>
        <MyTickets/>
    </Layout>
)

export default MyTicketsPage