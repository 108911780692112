import React from "react";

import {Question} from "../icons";
import "./Faq.css";
const Faq = ({className = '', setShowFaq}) => {
    return (
        <button onClick={() => setShowFaq(true)} className={`FaqButton ${className}`}><Question/></button>
    )
}

export default Faq