import { Fragment, useState } from 'react';

import Button from 'components/button/Button';
import PhoneInput from 'components/input/PhoneInput';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { otp } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import CustomProgress from '../components/CustomProgress';

function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      number: '',
    },
    validationSchema: Yup.object({
      number: Yup.string().required('Nömrə tələb olunur.'),
    }),
    onSubmit: (values) => {
      enableLoading();
      const number = values.number.replace(/[()+\s-]/g, '');
      otp(number)
        .then(() => {
          props.fulfillPhoneNumber(values.number);
          disableLoading();
          formik.resetForm();
          navigate('/auth/forgot-password/otp-verification');
        })
        .catch((err) => {
          disableLoading();
          setErrMsg(
            err.response?.data?.errors?.['Number']?.[0] ??
              err.response?.data?.message ??
              'Xəta baş verdi.'
          );
        });
    },
  });

  const handleChange = (e) => {
    formik.setFieldValue('number', e.target.value);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Pin-i yenilə</title>
      </Helmet>
      <form onSubmit={formik.handleSubmit} className="auth__forgot-password">
        <Link to="/" className="auth__logo">
          <img src="/media/topaz-nard-logo-black.svg" alt="Topaz" />
        </Link>
        <h1 className="auth__title">Şifrəni unutdunuz?</h1>
        <div className="section-2">
          <p className="auth__text-gray mb-5">
            Zəhmət olmasa şifrəni yeniləmək üçün mobil nömrənizi daxil edin
          </p>
        </div>
        <div className="auth__forgot-password--inner">
          <label className="mb-2">Telefon nömrəsi</label>
          <div className="mb-4">
            <PhoneInput
              name="number"
              value={formik.values.number}
              onChange={handleChange}
              placeholder="(+994)_ _-_ _ _-_ _-_ _"
              className="w-100"
            />
          </div>
          {formik.touched.number && formik.errors.number ? (
            <p className="error-text my-2">{formik.errors.number}</p>
          ) : null}
          {errMsg && <p className="error-text my-2">{errMsg}</p>}
          <div className="d-flex justify-content-between">
            <Button type="button" variant="secondary" onClick={() => navigate('/auth/login')}>
              Ləğv et
            </Button>
            <Button type="submit">Göndər</Button>
          </div>
        </div>
        {loading && (
          <div className="custom-progress">
            <CustomProgress />
          </div>
        )}
      </form>
    </Fragment>
  );
}

export default connect(null, auth.actions)(ForgotPassword);
