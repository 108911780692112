import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const useStyles = createUseStyles({
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: 500,
  },
  closeButton: {
    width: '36px',
    height: '36px',
    border: 'unset',
    borderRadius: '50%',
    position: 'absolute',
    right: '30px',
    top: '20px',
    background: '#1E1E1E',
    padding: 'unset',

    '& svg': {
      stroke: '#FFFFFF',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxWidth: '650px',
  },
  text: {
    maxWidth: '474px',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  inputContainer: {
    padding: '12px 16px',
    borderRadius: '10px',
    background: '#F5F5F5',
    '& svg': {
      marginRight: '8px',
    },
  },
  input: {
    border: '1px solid transparent',
    background: '#f5f5f5',
    outline: 'unset',
    borderRadius: '8px',
    padding: '16px',
    width: '100%',
    marginBottom: '6px',
  },
  select: {
    border: 'unset',
    background: '#f5f5f5',
    outline: 'unset',
    borderRadius: '8px',
    padding: '16px',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    background: '#00AFF5',
    width: '100%',
  },
  randomButton: {
    background: '#3bd05c40',
    color: '#16CA68',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    width: '100%',
  },
  greenCircle: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: '4px solid #34C759',
  },
  greenCircleFull: {
    backgroundColor: '#34C759',
  },
  agents: {
    width: '100%',
    display: 'flex',
    gap: '12px',
  },
  submit: {
    backgroundColor: '#2098e1',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    textAlign: 'center',
    padding: '10px',
    borderRadius: '30px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    color: '#FFFFFF !important',
    height: '24px !important',
    width: '24px !important',
  },
  hint: {
    color: '#393E46',
    fontSize: '12px',
    fontWeight: '400',
    marginBottom: '12px',
    lineHeight: '16px',
  },
  red: {
    color: 'red',
  },
  inputError: {
    borderColor: 'red',
  },
  warning: {
    fontSize: '12px',
    fontWeight: '400',
    color: 'red',
  },
});

export default function TopupBalanceCreditCard({ onHide }) {
  const [agents] = useState([
    { label: '123456', value: '123456' },
    { label: '171717', value: '171717' },
  ]);
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      amount: '',
      agentNumber: 926926,
      random: false,
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required()
        .min(3, 'Ən azı 3 AZN köçürmə edə bilərsiniz.'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const res = await axios.post('payment/cib-create-order', {
          amount: values.amount,
          agentNumber: 926926,
        });
        window.location.href = res.data?.checkoutUrl;
        onHide(false);
      } catch (err) {
        toast.error(err.response?.data?.message ?? 'Xəta baş verdi.');
        console.log(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  function handleClose() {
    onHide(false);
  }

  useEffect(() => {
    if (!formik.values.amount && formik.submitCount > 0) {
      toast.error('Məbləğ daxil edin.');
    }
  }, [formik.submitCount, formik.values.agentNumber]);

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <div className="w-100 d-flex flex-column gap-2 flex-wrap flex-sm-nowrap">
          <p className={classes.warning}>
            *Balans artırarkən problem yaşanarsa, 926 nömrəsi ilə əlaqə
            saxlayın.
          </p>
          <input
            type="text"
            className={`${classes.input} ${
              formik.errors.amount ? classes.inputError : ''
            }`}
            placeholder="Məbləğ*"
            inputMode="numeric"
            {...formik.getFieldProps('amount')}
            onChange={(e) => {
              const regex = /^[0-9.]+$/;
              if (regex.test(e.target.value) || e.target.value === '') {
                formik.setFieldValue('amount', e.target.value);
              }
            }}
          />
          <p
            className={`${classes.hint} ${
              formik.errors.amount ? classes.red : ''
            }`}
          >
            Minimum 3 AZN köçürə bilərsiniz. Topaznard tərəfindən komissiya
            tətbiq olunmur. Bankınız tərəfindən komissiya tutula bilər. Ətraflı
            məlumat üçün bankınızla əlaqə saxlamağınızı xahiş edirik. Xarici
            bank kartları istifadə oluna bilməz.
          </p>
        </div>
        <button
          className={`${classes.submit} cib_deposit`}
          type="submit"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <CircularProgress className={classes.progress} />
          ) : (
            'Vauçer al və balansı artır'
          )}
        </button>
      </form>
    </div>
  );
}
