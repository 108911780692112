export function logMessageOncePerWeek(action = () => null) {
  const lastLoggedTime = localStorage.getItem('lastLoggedTime');
  const currentTime = new Date().getTime();

  if (!lastLoggedTime) {
    action();
    localStorage.setItem('lastLoggedTime', currentTime);
  } else {
    const timeDifference = currentTime - lastLoggedTime;
    const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

    if (timeDifference >= oneWeekInMilliseconds) {
      action();
      localStorage.setItem('lastLoggedTime', currentTime);
    }
  }
}
