import {useCallback, useEffect, useMemo, useState, useRef} from 'react';

import {IconButton} from "@mui/material";
import Checkbox from 'components/Checkbox';
import {InfoIcon} from 'components/icons';
import RenderIf from 'components/RenderIf';
import Tooltip from 'components/tooltip';
import {format} from 'date-fns';
import { IoPrintOutline } from "react-icons/io5";
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {toast} from 'react-toastify';

import MultiplySelect from "../../../bron/components/MultiplySelect";
import Print from "../../../bron/components/Print"
import {
    checkCirculationIsNotPlayed,
    idealGameOptionChooser,
    popularGameOptionChooser,
    predictionsMonitor,
    randomGameOptionChooser,
    ticketPriceCalculator,
} from '../../helpers';
import {
    createVoidTicket,
    getCirculationGames,
    getExactTicketsCount,
} from '../../requests';
import {GoMyTickets, InfoToast} from '../Toastify';
import './PlayCirculation.css';

const selections = [
    {
        id: 1,
        label: 'Populyar',
    },
    {
        id: 2,
        label: 'İdeal',
    },
    {
        id: 3,
        label: 'Təsadüfi oyun',
    },
];

const tooltipData = [
    {
        id: 1,
        title: 'Populyar seçim nədir?',
        description:
            'Oyunçular tərəfindən 13 oyun üçün ən çox işarələnən seçimlərdən ibarət bir sütundur.Məs: Barselona və Real Madrid oyununda oyunçuların 70%i Barselona komdandasının qələbəsini, 20%i heç-heçə, 10%-i isə Real Madrid qələbəsini işarələyibsə, siz Populyar seçim seçdikdə Barselona qələbəsi üçün olan 1 xanası işarələnəcəkdir.',
    },
    {
        id: 2,
        title: 'İdeal seçim nədir?',
        description:
            '13 oyunluq turda ilk 8 oyun üçün ən çox seçim faizi olan xana, növbəti 3 oyun üçün seçim faizi ikinci yerdə olan xana, son 2 oyun üçün isə ən aşağı seçim faizi olan xanalar işarələnməklə ideal seçim formalaşdırılır.',
    },
];

const initialTimesValue = {
    label: "1X",
    value: 1
}

const PlayCirculation = ({circulations, onCreateVoidTicket, refetch}) => {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const initialCirculationId = params.get("circulationId");

    const [activeCirculationId, setActiveCirculationId] = useState(
        initialCirculationId ? +initialCirculationId : ""
    );
    const [activeSelection, setActiveSelection] = useState(0);
    const [initialTicketGames, setInitialTicketGames] = useState([]);
    const [ticketGames, setTicketGames] = useState([]);
    const [ticketPrice, setTicketPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(false);
    const [columnPrice, setColumnPrice] = useState();
    const [exactTicketCount, setExactTicketCount] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [times, setTimes] = useState(initialTimesValue);
    const [showPrintView, setShowPrintView] = useState(false);

    const circulation =
        useMemo(
            () =>
                circulations.find(
                    (circulation) => circulation.id === activeCirculationId
                ),
            [circulations, activeCirculationId]
        ) ?? {};
    const navigate = useNavigate();

    const {isAuth} = useSelector((state) => ({
        isAuth: state.auth.token !== undefined,
    }));
    const navigateCirculationTickets = () =>
        navigate(`/circulations/${circulation.id}/tickets`);

    const selectorHandler = useCallback(
        (id) => {
            const selectionsHandlers = {
                1: () => {
                    if (checkCirculationIsNotPlayed(ticketGames)) {
                        return toast.info('Bu tur uçun hələ bilet yaradılmayıb.');
                    }

                    const games = popularGameOptionChooser(initialTicketGames);
                    const ticketPrice = ticketPriceCalculator(games, columnPrice);

                    setTicketPrice(ticketPrice);
                    setTicketGames(games);
                },
                2: () => {
                    if (checkCirculationIsNotPlayed(ticketGames)) {
                        return toast.info('Bu tur uçun hələ bilet yaradılmayıb.');
                    }

                    const games = idealGameOptionChooser(initialTicketGames);
                    const ticketPrice = ticketPriceCalculator(games, columnPrice);

                    setTicketPrice(ticketPrice);
                    setTicketGames(games);
                },
                3: () => {
                    const games = randomGameOptionChooser(initialTicketGames);
                    const ticketPrice = ticketPriceCalculator(games, columnPrice);

                    setTicketPrice(ticketPrice);
                    setTicketGames(games);
                },
            };
            selectionsHandlers[id]();
            setActiveSelection(id);
        },
        [initialTicketGames, columnPrice, ticketGames]
    );

    const printRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: () => setShowPrintView(false)
    })

    function handleChangeCirculation(id) {
        if (id !== activeCirculationId) {
            setActiveCirculationId(id);
            setTicketGames([]);
            setInitialTicketGames([]);
            setActiveSelection(0);
            navigate(`/bron?circulationId=${id}`)
        }
    }

    function handleResetSelections() {
        setTicketPrice(0);
        setActiveSelection(0);
        setTicketGames(initialTicketGames);
        setExactTicketCount(0);
    }

    function handleChangeGameOption(e, index) {
        setActiveSelection(0);
        const games = ticketGames.map((game, gameIndex) => {
            if (gameIndex === index)
                return {
                    ...game,
                    [e.target.name]: e.target.checked,
                };
            return {...game};
        });
        const ticketPrice = ticketPriceCalculator(games, columnPrice);
        setTicketPrice(ticketPrice);
        setTicketGames(games);
    }

    function handleSubmit() {
        setIsSubmitting(true);
        const areAllMatchesPredicted = predictionsMonitor(ticketGames);

        if (!isAuth) {
            navigate('/auth');
            return;
        }

        if (!areAllMatchesPredicted) {
            toast.info(InfoToast);
            setIsSubmitting(false);
        } else if (isAuth) {
            const games = ticketGames.map((game) => ({
                gameId: game.id,
                option1: game.option1,
                optionX: game.optionX,
                option2: game.option2,
            }));
            const body = {
                ticketGames: games,
                circulationId: circulation.id,
                type: activeSelection,
                times: times.value
            }
            createVoidTicket(body)
                .then((res) => {
                    toast.success(GoMyTickets);
                    setIsSubmitting(false);
                    onCreateVoidTicket({
                        ticketId: res.data?.id,
                        barcode: res.data?.ticketNumber,
                        createdDate: res.data?.createdDate,
                        price: res.data?.price,
                    });
                    handleResetSelections();
                    setTimes(initialTimesValue);
                })
                .catch((err) => {
                    const message = err.response?.data?.message ?? 'Xəta baş verdi';
                    if (message === 'Tur hazırda aktiv deyil' || message === 'Mərc bitmişdir. Əməliyyat uğursuzdur.') {
                        refetch()
                    }
                    toast.error(message);
                    setIsSubmitting(false);
                });
        }
    }

    useEffect(() => {
        if(activeCirculationId) {
            setIsLoading(true);
            getCirculationGames(activeCirculationId)
                .then((res) => {
                    const ticketGames =
                        res.data?.games?.map((game) => ({
                            ...game,
                            option1: false,
                            optionX: false,
                            option2: false,
                        })) || [];
                    setColumnPrice(res.data?.columnPrice);
                    setTicketGames(ticketGames);
                    setInitialTicketGames(ticketGames);
                })
                .catch(() => null)
                .finally(() => setIsLoading(false));
        }
    }, [activeCirculationId]);

    useEffect(() => {
        const areAllMatchesPredicted = predictionsMonitor(ticketGames);

        if (areAllMatchesPredicted) {
            getExactTicketsCount(ticketGames, circulation.id)
                .then((res) => setExactTicketCount(res.data))
                .catch(() => null);
        }
    }, [ticketGames, circulation.id]);

    useEffect(() => {
        if(!activeCirculationId) {
            setActiveCirculationId(circulations?.[0]?.id);
            navigate(`/bron?circulationId=${circulations?.[0]?.id}`)
        }
    }, [activeCirculationId, circulations, navigate])

    useEffect(() => {
        if(ticketPrice) {
            setTotalPrice(ticketPrice * times.value);
        }
    }, [ticketPrice, times])

    useEffect(() => {
        if(showPrintView) handlePrint()
    }, [showPrintView, handlePrint])

    return (
        <div className="circulation__wrapper">
            <div className="circulation">
                <div className="circulation__tabs">
                    {circulations?.map((circulation, index) => (
                        <button
                            key={index}
                            type="button"
                            disabled={isLoading}
                            onClick={() => handleChangeCirculation(circulation.id)}
                            className={`circulation__tab ${
                                activeCirculationId === circulation.id
                                    ? 'circulation__tab-active'
                                    : ''
                            }`}
                        >
                            {circulation.productName}
                        </button>
                    ))}
                </div>
                <div className="circulation__details">
                    <div className="circulation__details-item">
                        <span className="circulation__details-title">Tur nömrəsi</span>
                        <span className="circulation__details-description">
              {circulation.code}
            </span>
                    </div>
                    <div className="circulation__details-item">
                        <span className="circulation__details-title">Hovuz məbləği</span>
                        <span
                            className={`circulation__details-description ${
                                !circulation?.possibleSeeTotalAmount
                                    ? 'circulation__details-blur'
                                    : ''
                            }`}
                        >
              {circulation.totalAmount} AZN
            </span>
                    </div>
                    <div className="circulation__details-item">
            <span className="circulation__details-title">
              Mərcin bitmə tarixi
            </span>
                        <span className="circulation__details-description circulation__details-description--blue">
              {circulation.expiredDate &&
                  format(new Date(circulation.expiredDate), 'dd-MM-yyyy HH:mm')}
            </span>
                    </div>
                    <div className="circulation__details-item">
                        <span className="circulation__details-title">Tur bitmə tarixi</span>
                        <span className="circulation__details-description circulation__details-description--red">
              {circulation.resultedDate &&
                  format(new Date(circulation.resultedDate), 'dd-MM-yyyy HH:mm')}
            </span>
                    </div>
                    <IconButton onClick={() => setShowPrintView(true)} title="Çap et" className="ms-auto">
                        <IoPrintOutline/>
                    </IconButton>
                    {/*<button className="ms-auto"><IoPrintOutline/></button>*/}
                    <button
                        onClick={navigateCirculationTickets}
                        className="circulation__details-button"
                    >
                        Biletlər
                    </button>
                </div>
                <div className="circulation__games">
                    <div className="circulation__games-header">
                        <div></div>
                        <div>1</div>
                        <div>x</div>
                        <div>2</div>
                    </div>
                    <div className="circulation__games-body">
                        {ticketGames.map((game, index) => (
                            <div key={game.id} className="circulation__game">
                                <div className="circulation__game-text">
                                    <p className="circulation__game-title">
                                        {game.hostClubName} - {game.guestClubName}
                                    </p>
                                    <p className="circulation__game-date">
                    <span>
                      {game.startDate && format(new Date(game.startDate), 'dd-MM-yyyy')}
                    </span>
                                        <span>{game.startDate && format(new Date(game.startDate), 'HH:mm')}</span>
                                    </p>
                                </div>
                                <div className="d-flex gap-2 align-items-center circulation__game-selection">
                                    <span className="mobile-option-name">1</span>
                                    <Checkbox
                                        name="option1"
                                        checked={ticketGames[index]?.option1}
                                        onChange={(e) => handleChangeGameOption(e, index)}
                                    />
                                    <span>
                    {Boolean(
                        game.option1Ratio ||
                        game.optionXRatio ||
                        game.option2Ratio
                    ) && `${game.option1Ratio}%`}
                  </span>
                                </div>
                                <div className="d-flex gap-2 align-items-center circulation__game-selection">
                                    <span className="mobile-option-name">x</span>
                                    <Checkbox
                                        name="optionX"
                                        checked={ticketGames[index]?.optionX}
                                        onChange={(e) => handleChangeGameOption(e, index)}
                                    />
                                    <span>
                    {Boolean(
                        game.option1Ratio ||
                        game.optionXRatio ||
                        game.option2Ratio
                    ) && `${game.optionXRatio}%`}
                  </span>
                                </div>
                                <div className="d-flex gap-2 align-items-center circulation__game-selection">
                                    <span className="mobile-option-name">2</span>
                                    <Checkbox
                                        name="option2"
                                        checked={ticketGames[index]?.option2}
                                        onChange={(e) => handleChangeGameOption(e, index)}
                                    />
                                    <span>
                    {Boolean(
                        game.option1Ratio ||
                        game.optionXRatio ||
                        game.option2Ratio
                    ) && `${game.option2Ratio}%`}
                  </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <RenderIf condition={Boolean(exactTicketCount)}>
                <div className="d-flex gap-2">
                    <span className="fw-bold">Eyni bilet sayı:</span>
                    <span className="text-red">{exactTicketCount}</span>
                </div>
            </RenderIf>
            <div className="d-flex gap-2">
                <span className="fw-bold">Sütun üzrə mərc məbləği:</span>
                <span className="text-red">{columnPrice} AZN</span>
            </div>
            <div className="d-flex gap-2">
                <span className="fw-bold">Cəmi mərc məbləği:</span>
                <span className="text-red">
          {Boolean(totalPrice) && `${totalPrice} AZN`}
        </span>
            </div>
            <div className="toolbar">
                <div className="toolbar__inner">
                    <div className="d-flex flex-wrap gap-4 align-items-end">
                        <div className="toolbar__quick-selections">
                            <div className="toolbar__info">
                                <span>Aşağıdakı hazır seçimlər ilə sürətli mərc edin</span>
                                <Tooltip data={tooltipData} id="my-tooltip">
                                    <InfoIcon/>
                                </Tooltip>
                            </div>
                            <div className="d-flex gap-2">
                                {selections.map((button) => (
                                    <button
                                        key={button.id}
                                        onClick={() => selectorHandler(button.id)}
                                        className={`toolbar__selection-button ${
                                            button.id === activeSelection
                                                ? 'toolbar__selection-button-active'
                                                : ''
                                        }`}
                                    >
                                        {activeSelection === button.id && (
                                            <svg className="animated-check" viewBox="0 0 24 24">
                                                <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none"/>
                                            </svg>
                                        )}
                                        {button.label}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <MultiplySelect onChange={(value) => setTimes(value)} value={times}/>
                    </div>
                    <div className="d-flex gap-2">
                        <button
                            onClick={handleResetSelections}
                            className="toolbar__button-light"
                        >
                            Seçimləri sıfırla
                        </button>
                        <button
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                            className="toolbar__button"
                        >
                            Bron et
                        </button>
                    </div>
                </div>
            </div>
            <RenderIf condition={showPrintView}>
                <Print circulationData={circulation} ref={printRef}/>
            </RenderIf>
        </div>
    );
};

export default PlayCirculation;
