import React, {useState, useEffect} from 'react';

import Select from "react-select";

import './DateInput.css';

const days = [
    {
        "label": "1",
        "value": "01"
    },
    {
        "label": "2",
        "value": "02"
    },
    {
        "label": "3",
        "value": "03"
    },
    {
        "label": "4",
        "value": "04"
    },
    {
        "label": "5",
        "value": "05"
    },
    {
        "label": "6",
        "value": "06"
    },
    {
        "label": "7",
        "value": "07"
    },
    {
        "label": "8",
        "value": "08"
    },
    {
        "label": "9",
        "value": "09"
    },
    {
        "label": "10",
        "value": "10"
    },
    {
        "label": "11",
        "value": "11"
    },
    {
        "label": "12",
        "value": "12"
    },
    {
        "label": "13",
        "value": "13"
    },
    {
        "label": "14",
        "value": "14"
    },
    {
        "label": "15",
        "value": "15"
    },
    {
        "label": "16",
        "value": "16"
    },
    {
        "label": "17",
        "value": "17"
    },
    {
        "label": "18",
        "value": "18"
    },
    {
        "label": "19",
        "value": "19"
    },
    {
        "label": "20",
        "value": "20"
    },
    {
        "label": "21",
        "value": "21"
    },
    {
        "label": "22",
        "value": "22"
    },
    {
        "label": "23",
        "value": "23"
    },
    {
        "label": "24",
        "value": "24"
    },
    {
        "label": "25",
        "value": "25"
    },
    {
        "label": "26",
        "value": "26"
    },
    {
        "label": "27",
        "value": "27"
    },
    {
        "label": "28",
        "value": "28"
    },
    {
        "label": "29",
        "value": "29"
    },
    {
        "label": "30",
        "value": "30"
    },
    {
        "label": "31",
        "value": "31"
    }
];

const month = [
    {
        label: "Yanvar",
        value: "01"
    }, {
        label: "Fevral",
        value: "02"
    }, {
        label: "Mart",
        value: "03"
    }, {
        label: "Aprel",
        value: "04"
    }, {
        label: "May",
        value: "05"
    }, {
        label: "İyun",
        value: "06"
    }, {
        label: "İyul",
        value: "07"
    }, {
        label: "Avgust",
        value: "08"
    }, {
        label: "Sentyabr",
        value: "09"
    }, {
        label: "Oktyabr",
        value: "10"
    }, {
        label: "Noyabr",
        value: "11"
    }, {
        label: "Dekabr",
        value: "12"
    }];

const years = [];

const currentYear = new Date().getFullYear();
const startYear = currentYear - 19;

Array.from({length: 100}).forEach((val, index) => {
    years.push({
        label: (startYear - index + 1).toString(),
        value: startYear - index + 1
    })
});

const styles = {
    control: (baseStyles) => ({
        ...baseStyles,
        borderRadius: "10px",
        background: "#F5F5F5",
        border: "unset"
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: '10px 0 10px 16px'
    }),
    indicatorSeparator: () => ({
        display: "none"
    })
}

const DateInput = ({onChange, onTouch, name}) => {
    const [date, setDate] = useState({
        year: null,
        month: null,
        day: null
    });

    function handleChange(newValue, action) {
        setDate(prevState => ({
            ...prevState, [action.name]: newValue
        }));
    }

    useEffect(() => {
        if (date.year && date.month && date.day) {
            const value = `${date.year.value}-${date.month.value}-${date.day.value}`;
            onTouch(name, true);
            onChange(value);
        }
    }, [date])

    return (
        <div className="d-flex gap-2">
            <Select
                name="day"
                options={days}
                placeholder="Gün"
                className="w-100"
                onChange={handleChange}
                styles={styles}
            />
            <Select
                name="month"
                options={month}
                placeholder="Ay"
                className="w-100"
                onChange={handleChange}
                styles={styles}
            />
            <Select
                name="year"
                options={years}
                placeholder="İl"
                className="w-100"
                onChange={handleChange}
                styles={styles}
            />
        </div>
    )
}

export default DateInput