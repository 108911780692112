import axios from 'axios';

const GET_CIRCULATION_GAMES = 'circulations/circulationGames';
const GET_CIRCULATIONS = 'circulations/filter';
const GET_PRODUCTS = 'products';

const getCirculationGames = (id) =>
    axios.get(`${GET_CIRCULATION_GAMES}?id=${id}`);

const getExactTicketsCount = (ticketGames = [], circulationId) =>
    axios({
        url: `tickets/exactTicketsCount`,
        method: 'POST',
        data: {
            ticketGames: ticketGames.map((game) => ({
                gameId: game.id,
                option1: game.option1,
                optionX: game.optionX,
                option2: game.option2,
            })),
            circulationId,
        },
    });

const createVoidTicket = (body) =>
    axios.post(`voidTickets/create`, body);

const getCirculations = () =>
    axios.get(`${GET_CIRCULATIONS}`, {
        params: {isActive: true, isExpired: false, sortField: "CirculationJackpot.Amount"},
    });

const getProducts = () => axios.get(GET_PRODUCTS);

export {
    createVoidTicket,
    getCirculationGames,
    getCirculations,
    getExactTicketsCount,
    getProducts
};
