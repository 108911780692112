import React from "react";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import {createUseStyles} from 'react-jss'
import "./BetHistory.css";

const useStyles = createUseStyles({
    table: {
        background: "#333",
        borderRadius: "18px"
    },
    tableHeadCell: {
        color: "#7B7B7B",
        borderBottom: "unset"
    },
    tableCell: {
        color: "#FFFFFF",
        borderBottom: "unset"
    },
    lost: {
        borderRadius: "90px",
        background: "rgba(254, 99, 99, 0.25)",
        color: "#F50000",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "24px",
        padding: "8px 16px",
        width: "fit-content"
    },
    won: {
        borderRadius: "90px",
        background: "#D8EACA",
        color: "#26752A",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "24px",
        padding: "8px 16px",
        width: "fit-content"
    }
});

const headCells = [
    {
        id: "date",
        label: "Date"
    },
    {
        id: "transactionId",
        label: "Transaction ID"
    },
    {
        id: "balance",
        label: "Balance"
    },
    {
        id: "bets",
        label: "Bets"
    },
    {
        id: "status",
        label: "Status"
    }
];

const data = [
    {
        id: 1,
        date: "08.07.2024 06:17:23",
        transactionId: "6456656432543543",
        balance: "50 azn",
        bets: "6456656432543543",
        status: "lost"
    },
    {
        id: 1,
        date: "08.07.2024 06:17:23",
        transactionId: "6456656432543543",
        balance: "50 azn",
        bets: "6456656432543543",
        status: "won"
    },
]

const BetHistory = () => {
    const classes = useStyles();

    return (
        <div className="bet-history">
            <div className="container">
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {headCells.map(item => (
                                    <TableCell key={item.id} className={classes.tableHeadCell}>{item.label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell className={classes.tableCell}>{item.date}</TableCell>
                                    <TableCell className={classes.tableCell}>{item.transactionId}</TableCell>
                                    <TableCell className={classes.tableCell}>{item.balance}</TableCell>
                                    <TableCell className={classes.tableCell}>{item.bets}</TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <div className={item.status === 'lost' ? classes.lost : classes.won}>
                                            {item.status === 'lost' ? "Lost" : "Won"}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default BetHistory;