import { getRefreshToken } from '../modules/auth/_redux/authCrud';
import * as auth from '../modules/auth/_redux/authRedux';
import store from '../redux/store';

const { dispatch } = store;

export default function setupAxios(axios, store) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { token },
      } = store.getState();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      const { setRefreshToken, requestLogout } = auth.actions;
      const {
        auth: { refreshToken },
      } = store.getState();
      if (error.response) {
        if (
          refreshToken &&
          error.response.status === 401 &&
          error.response.data.message === 'User is not authenticated' &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;

          getRefreshToken(refreshToken)
            .then(
              ({ data: { token, expiresAt, refreshToken, checkToken } }) => {
                dispatch(
                  setRefreshToken(token, expiresAt, refreshToken, checkToken)
                );

                return axios(originalRequest);
              }
            )
            .catch(() => {
              dispatch(requestLogout());
            });
        }
      }

      return Promise.reject(error);
    }
  );
}
