import { useState } from 'react';

import AccordionItem from './AccordionItem';

import './Accordion.css';

const faqData = [
  {
    id: 1,
    title: 'Necə qeydiyyatdan keçim? 📝',
    description: (
      <p>
        1️⃣ <b>Mobil nömrə və doğum tarixi:</b> Əvvəlcə mobil nömrənizi və doğum
        tarixinizi qeyd edin, sonra <b>"Davam et"</b> düyməsinə basın.
        <br />
        2️⃣ <b>OTP kodun təsdiqi:</b> Nömrənizə göndərilən OTP kodunu daxil edib
        təsdiqləyin. <br /> 3️⃣ <b>Şifrə yaradılması:</b> Şifrəni və onun
        təkrarını daxil edin. Daha sonra hesabınızı təsdiq edin.
        <br />
        <br /> 🔞 <b>Qeyd:</b> Doğum tarixiniz{' '}
        <b>18 yaşdan aşağı olmamalıdır.</b> <br /> 📲 <b>OTP kod limitləri:</b>{' '}
        OTP kod maksimum 3 dəfə göndərilir. Əgər limiti keçsəniz, xahiş olunur
        ki, <b>926 </b> nömrəsi ilə əlaqə saxlayasınız.
        <br />
        <br /> Hesab yaratmaq bu qədər sadədir! 🌟
      </p>
    ),
    open: false,
  },
  {
    id: 2,
    title: 'Balansımı online necə artıra bilərəm? 💳',
    description: (
      <p>
        <b>Balansı online artırmaq</b>
        <br />
        <br />
        Balansınızı artıq <b>3 AZN-dən başlayaraq</b> bank kartları ilə
        asanlıqla artırmaq mümkündür. ✔️ <br /> 🔹 İstənilən bank kartından
        istifadə edə bilərsiniz.
        <br /> 🔹 <b>TopazNard</b> platforması əlavə{' '}
        <b>komissiya haqqı tutmur!</b>
        <br />
        <br />
        <b>Uduş məbləğini bank kartına köçürmək</b>
        <br />
        <br />
        Uduşunuzu <b>5 AZN-dən başlayaraq</b> bank kartınıza köçürə bilərsiniz.
        <br />
        ✔️ 🔹 Əlavə <b>komissiya haqqı yoxdur.</b>
        <br />
        <br />
        ⚠️ <b>Qeyd</b>: Yalnız <b>uduş məbləğinizi</b> balansdan çıxara
        bilərsiniz.
        <br />
        <br />
        <b>Həm artır, həm də köçür! Rahat və komissiyasız!</b> 😊
      </p>
    ),
    open: false,
  },
  {
    id: 3,
    title: 'Balansda məbləğ olduğu halda yeni vauçer əlavə edə bilərəmmi? ⚠️',
    description: (
      <p>
        Xeyr, balansınızda məbləğ olduğu halda yeni vauçer əlavə etmək mümkün
        deyil. Balansınız ancaq 0 olduğu halda yeni vauçeri istifadə edə
        bilərsiniz.
        <br />
        <br />
        ➡️ Bunun üçün Uduş vauçeri çıxartmaq seçimini edərək balansı sıfırlaya
        bilərsiniz. Beləliklə, yeni vauçeri əlavə etmək imkanı əldə edəcəksiniz.
      </p>
    ),
    open: false,
  },
  {
    id: 4,
    title: 'Vauçer nədir? 🤔',
    description: (
      <p>
        Vauçer, <b>Topaz məntəqələrində</b> satıcıların sizdən ödənişi qəbul
        etdikdən sonra təqdim etdiyi <b>qəbzdir</b>. Bu qəbz, ödəniş etdiyiniz
        məbləğə uyğun olaraq hazırlanır. Vauçer əldə etdikdən sonra{' '}
        <u>topaznard.az</u> saytında mərc edə bilərsiniz.
      </p>
    ),
    open: false,
  },
  {
    id: 5,
    title: 'Vauçer kodu nədir? 🔢',
    description: (
      <p>
        Vauçer kodu, qəbzin üzərində qeyd olunan <b>16 rəqəmli xüsusi koddur</b>
        . Bu kodu istifadə edərək balansınızı artırmaq mümkündür.
        <br />
        🕒 <b>Qeyd:</b>
        <br />
        <br />● Vauçer, yaradıldığı andan <b>3 gün ərzində</b> istifadə
        olunmalıdır.
        <br />● Minimum məbləğ: <b>1 AZN</b>
        <br />● Maksimum məbləğ: <b>1000 AZN</b>
      </p>
    ),
    open: false,
  },
  {
    id: 6,
    title:
      'Topaz məntəqəsində Vauçeri necə əldə edə və balansımı necə artıra bilərəm? 💸',
    description: (
      <p>
        1️⃣ <b>Vauçer əldə etmək:</b>
        <br />
        <br /> İstənilən <b>Topaz məntəqəsinə</b> yaxınlaşaraq mərc etmək
        istədiyiniz məbləği satıcıya təqdim edib həmin məbləğdə vauçer əldə edə
        bilərsiniz.
        <br />
        <br /> 2️⃣ <b>Balans artırmaq:</b>
        <br />
        <br /> ● <u>topaznard.az</u> saytına daxil olun.
        <br /> ● Vauçerin üzərindəki barkodu skan edin və ya{' '}
        <b>16 rəqəmli kodu</b> əl ilə daxil edin.
        <br /> ● Balansınız dərhal artırılacaq.
        <br />
        <br /> ⚠️ <b>Qeyd:</b> Balansınızda məbləğ olduğu halda yeni vauçer
        əlavə etmək mümkün deyil. Balansınız ancaq 0 olduğu halda yeni vauçeri
        istifadə edə bilərsiniz. Bunun üçün Uduş vauçeri çıxartmaq seçimini
        edərək balansı sıfırlaya bilərsiniz
      </p>
    ),
    open: false,
  },
  {
    id: 7,
    title: 'Mərc limitləri və maksimum ödənişlər 🎯',
    description: (
      <p>
        🔹 <b>Minimum mərc:</b> 1 gediş üçün ən az mərc məbləği <b>0,10 ₼</b>.
        <br />
        🔹 <b>Maksimum mərc:</b> 1 gediş üçün ən çox mərc məbləği <b>100 ₼.</b>{' '}
        <br />
        🔹 <b>Maksimum qazanc:</b> 1 gedişdən əldə edilə biləcək ən yüksək
        qazanc <b>3000 ₼</b>.<br />
        <br /> <b>Mərc məbləği seçimləri:</b> 0.10 AZN, 0.20 AZN, 0.50 AZN, 1
        AZN, 5 AZN, 10 AZN, 20 AZN, 50 AZN, 100 AZN <br />
        <br /> ⚠️ <b>Qeyd:</b> Əgər sabit mərc qoyduğunuz zaman balansınızda
        lazım olan məbləğ yoxdursa, mövcud balans tam sərf olunacaq.
      </p>
    ),
    open: false,
  },
  {
    id: 8,
    title: 'Nərd oyunu üzrə mərc qaydaları 🎲',
    description: (
      <p>
        1️⃣ <b>Oyun məqsədi:</b>
        <br />
        Bu oyunun məqsədi, iştirak edən idmançılar (yarışçılar) tərəfindən
        atılan <b>zərlərin</b> dəyərini təxmin etməkdir. Zərlərin üzərində{' '}
        <b>1-dən 6-ya qədər rəqəmlər</b> var və zərlər <b>mavi və qırmızı</b>{' '}
        rənglərdədir.
        <br />
        <br />
        2️⃣ <b>Oyun qaydaları:</b>
        <br />
        <br />● Yarış, <b>klassik nərd qaydalarına</b> uyğun keçirilir.
        <br />● İki idmançı, <b>iki hissədən (sol və sağ)</b> ibarət olan oyun
        lövhəsində zər atırlar.
        <br />● Hər bir idmançı, fərqli rəngdə olan <b>15 daşa</b> malikdir.
        <br />● Oyunun başlanğıcında, daşlar hər bir idmançı üçün oyun
        lövhəsinin <b>sağ tərəfində</b> yerləşdirilir.
        <br />● Zərlər <b>2 ədəd</b>-dir: <b>mavi və qırmızı</b>.<br />
        <br />
        3️⃣ <b>Zər atma:</b>
        <br />
        <br />
        ● İdmançılar növbə ilə zərləri atırlar.
        <br />● Zərlər eyni zamanda <b>zər atma qutusu (baffle box)</b>{' '}
        vasitəsilə atılır.
        <br />● Zərlərin atılması zamanı, hər iki zər{' '}
        <b>zər atma qutusunun səthində düz şəkildə durmalıdır</b>.<br />
        ● Əgər zərlər qutunun səthindən kənarda, üst-üstə və ya üfüqi vəziyyətdə
        qalarsa, zərlər yenidən atılır.
        <br />
        <br />
        4️⃣ <b>Daşların hərəkəti:</b>
        <br />
        <br />● Atışdan sonra, idmançı <b>öz daşlarını</b> atılan zərlərin
        rəqəmsal dəyərinə uyğun olaraq, lövhədəki müvafiq xanalara hərəkət
        etdirir. ● İdmançı yalnız <b>öz daşlarını</b> hərəkət etdirə bilər. ●
        Əgər zərlərdə <b>ikiqat</b> rəqəm çıxarsa, bu halda hər bir daşın
        hərəkəti <b>ikiqat sayılır</b>.<br />
        <br />
        5️⃣ <b>Yarışın məqsədi:</b>
        <br />
        <br />
        Yarışın məqsədi, bütün daşları <b>"ev"ə</b>, yəni oyun lövhəsinin son
        dörddə bir hissəsinə tam dövr etdirib yığmaqdır.
        <br />
        <br />● Sonra, <b>raqbinizdən əvvəl</b> daşları lövhədən çıxarmaq
        (atmaq) lazımdır.
        <br />
        <br />
        6️⃣<b>Baffle box:</b>
        <br />
        Zər atma qutusu, <b>nərd və digər zər oyunlarında</b> zərlərin atılması
        üçün istifadə olunan xüsusi bir qutudur. Bu qutu, zərlərin tamamilə
        təsadüfi şəkildə qarışdırılmasını təmin edir və hər atışda{' '}
        <b>ədalətli və qərəzsiz nəticələr</b> əldə edilməsini təmin edir.
      </p>
    ),
    open: false,
  },
  {
    id: 9,
    title: '“Classic” oyun növünün qaydaları 🎲',
    description: (
      <p>
        1️⃣ <b>1-ci market:</b>
        <br />
        İlk marketdə, <b>hansı rəngdə zərin</b> üzərindəki rəqəmin daha böyük
        olacağını təxmin edərək mərc edə bilərsiniz.
        <br />
        <br />● Seçimlər:{' '}
        <b>
          Mavi, Heç-heçə, Qırmızı, Mavi və ya Heç-heçə, Heç-heçə və ya Qırmızı
        </b>
        .<br />
        <br />
        2️⃣ <b>2-ci market:</b>
        <br />
        Bu marketdə, <b>mavi</b> və ya <b>qırmızı</b> zərin birinin və ya hər
        ikisinin üzərindəki rəqəmi <b>dəqiq təxmin</b> edərək mərc edə
        bilərsiniz.
        <br />
        <br />
        3️⃣ <b>3-cü market:</b>
        Burada, <b>mavi və qırmızı zərin rəqəmlərinin cəminin</b> "daha az" və
        ya "daha çox" olacağını təxmin edərək mərc edə bilərsiniz.
        <br />
        <br />
        🔹 <b>Mərc etmək:</b>
        <br />
        <br />●{' '}
        <b>Mərc məbləği: 0.10, 0.20, 0.50, 1, 5, 10, 20, 50 və 100 AZN</b>{' '}
        seçimləri mövcuddur.
        <br />● Hər gedişdə <b>20 saniyə</b> vaxt verilir ki, mərc məbləğinizi
        və mərcləri seçəsiniz.
        <br />● <b>Mərc məbləğinizi</b> ikiqat artıra, dəyişə və ya ləğv edə
        bilərsiniz.
        <br />● Həmçinin əvvəlki gedişinizi <b>bir düymə ilə təkrarlaya</b>{' '}
        bilərsiniz.
        <br />
      </p>
    ),
    open: false,
  },
  {
    id: 10,
    title: 'Nards 36 oyun növünün qaydaları 🎲',
    description: (
      <p>
        Mavi və qırmızı zərlər <b>1-dən 36-a qədər</b> rəqəmlərlə işarələnir və
        müxtəlif sətirlərə və sütunlara bölünür. Zərlərin üzərindəki saylara
        əsasən, <b>3 market</b> mövcuddur.
        <br />
        <br />
        1️⃣ <b>1-ci market: Mavi zərin rəqəmləri</b>
        <br />
        Bu marketdə, mavi rəngli zərin üzərindəki rəqəmləri təxmin edə
        bilərsiniz.
        <br />
        <br />● <b>"1-18"</b> bölməsində: Üzərində <b>1, 2 və 3</b> ehtimalı
        olan mavi zərlər.
        <br />● <b>"19-36"</b> bölməsində: Üzərində <b>4, 5 və 6</b> ehtimalı
        olan mavi zərlər.
        <br />
        <br />
        2️⃣ <b>2-ci market: Qırmızı zərin sıralaması</b>
        <br />
        Bu marketdə, qırmızı rəngli zərlərin hansı sətirdə olduğunu təxmin edə
        bilərsiniz.
        <br />
        <br />● <b>1-ci sətir:</b> Üzərində <b>3 və 6</b> ehtimalı olan qırmızı
        zərlər.
        <br />● <b>2-ci sətir:</b> Üzərində <b>2 və 5</b> ehtimalı olan qırmızı
        zərlər.
        <br />● <b>3-cü sətir:</b> Üzərində <b>1 və 4</b> ehtimalı olan qırmızı
        zərlər.
        <br />
        <br />
        3️⃣ <b>3-cü market: Mavi zərlərin sıralaması</b>
        <br />
        Bu marketdə, mavi rəngli zərlərin cəmi üzrə mərc edə bilərsiniz:
        <br />
        <br />● <b>"1-12"</b> bölməsində: Üzərində <b>1 və 2</b> ehtimalı olan
        mavi zərlər.
        <br />● <b>"13-24"</b> bölməsində: Üzərində <b>3 və 4</b> ehtimalı olan
        mavi zərlər.
        <br />● <b>"25-36"</b> bölməsində: Üzərində <b>5 və 6</b> ehtimalı olan
        mavi zərlər.
        <br />
        <br />
        🔹 <b>Mərc etmək:</b>
        <br />
        <br />●{' '}
        <b>Mərc məbləği: 0.10, 0.20, 0.50, 1, 5, 10, 20, 50 və 100 AZN</b>{' '}
        seçimləri mövcuddur.
        <br />● Hər gedişdə <b>20 saniyə</b> vaxt verilir ki, mərc məbləğinizi
        və mərcləri seçəsiniz.
        <br />● <b>Mərc məbləğinizi</b> ikiqat artıra, dəyişə və ya ləğv edə
        bilərsiniz.
        <br />
        ● Həmçinin əvvəlki gedişinizi b bir düymə ilə təkrarlaya bilərsiniz.
        <br />
      </p>
    ),
    open: false,
  },
];

const Accordion = () => {
  const [data, setData] = useState(faqData);

  const handleClick = (index) => () => {
    setData((prevState) =>
      prevState.map((item, idx) => {
        if (index === idx) {
          return { ...item, open: !item.open };
        }
        return { ...item, open: false };
      })
    );
  };

  return (
    <div className="accordion">
      {data.map((item, index) => (
        <AccordionItem
          key={item.id}
          title={item.title}
          description={item.description}
          onClick={handleClick(index)}
          open={item.open}
        />
      ))}
    </div>
  );
};

export default Accordion;
