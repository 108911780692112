import './style.css';

export default function Checkbox({ value, onChange, name, checked }) {
  return (
    <div className="checkbox">
      <input name={name} type="checkbox" value={value} onChange={onChange} checked={checked} />
      <span></span>
    </div>
  );
}
