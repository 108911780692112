import { Player, Controls } from '@lottiefiles/react-lottie-player';

const LottiePlayer = ({animation, width = '100px', height = '100px', speed = 1, ...rest}) => {
    return (
        <Player
            autoplay
            loop
            src={animation}
            style={{ height: height, width: width }}
            speed={speed}
            {...rest}
        >
            <Controls visible={false} />
        </Player>
    )
}

export default LottiePlayer