import React from "react";

import {Tooltip} from "react-tooltip";

import "./Tooltip.css";

const TooltipComponent = ({children, data, id}) => {

    const content = (
        data.map(item => (
            <div key={item.id} className="tooltip-container">
                <p className="tooltip-title">{item.title}</p>
                <p className="tooltip-description">{item.description}</p>
            </div>
        ))
    )

    return (
        <>
            <span id={id}>{children}</span>
            <Tooltip
                anchorSelect={`#${id}`}
                children={content}
                border="1px solid #EAECF0"
                style={{
                    borderRadius: '8px',
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 8px 0px rgba(9, 30, 66, 0.20)'
                }}
            />
        </>
    )
}

export default TooltipComponent