import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  SetPage: '[Set Page] Action',
  SetNumber: '[Set Number] Action',
  SetTicketNumber: '[Set Ticket Number] Action',
};

const initialState = {
  page: '/1',
  number: '1',
  ticketNumber: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: 'play',
    whitelist: ['page', 'ticketNumber'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetPage: {
        const { page } = action.payload;
        return { ...state, page };
      }
      case actionTypes.SetNumber: {
        const { number } = action.payload;
        return { ...state, number };
      }
      case actionTypes.SetTicketNumber: {
        const { ticketNumber } = action.payload;
        return { ...state, ticketNumber };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  setPage: (page) => ({
    type: actionTypes.SetPage,
    payload: { page },
  }),
  setNumber: (number) => ({
    type: actionTypes.SetNumber,
    payload: { number },
  }),
  setTicketNumber: (ticketNumber) => ({
    type: actionTypes.SetTicketNumber,
    payload: { ticketNumber },
  }),
};
