import * as React from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { azAZ } from '@mui/material/locale';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import axios from 'axios';
import AlertComponent from 'components/alert';
import RenderIf from 'components/RenderIf';
import { format } from 'date-fns';
import { paramsObjectToString } from 'helpers';
import { AiOutlineEye } from 'react-icons/ai';
import {createUseStyles} from 'react-jss'
import { useSelector } from 'react-redux';
import Select from 'react-select';
import './styles/style.css';

const theme = createTheme({}, azAZ);

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(2n)': {
    backgroundColor: '#EAF6FF',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headCells = [
  {
    id: 'id',
    label: 'Bilet ID',
  },
  {
    id: 'ticketNumber',
    label: 'Bilet nömrəsi',
  },
  {
    id: 'Circulation.Code',
    label: 'Tur nömrəsi',
  },
  {
    id: 'price',
    label: 'Məbləğ',
  },
  {
    id: 'winningAmount',
    label: 'Uduş məbləği',
  },
  {
    id: 'statusId',
    label: 'Bilet statusu',
  },
  {
    id: 'createdDate',
    label: 'Tarix',
  },
  {
    id: 'winningPaidStatus',
    label: 'Uduşun ödənilməsi',
  },
  {
    id: 'paidDateTime',
    label: 'Uduşun ödənilmə tarixi',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const useStyles = createUseStyles({
    activeHeadCell: {
      borderBottom: '1px solid #24A3FF !important',
      color: '#24A3FF !important',
    },
    actioveDirectionRow: {
      color: '#24A3FF !important',
    },
    TableRow: {
      padding: '0 35px !important',
    },
  });

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.TableRow}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={
                orderBy === headCell.id
                  ? `${classes.activeHeadCell} activeHeadCell`
                  : null
              }
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box className={classes.actioveDirectionRow} component="span">
                  {/*{order === 'desc' ? 'sorted descending' : 'sorted ascending'}*/}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  setIsLoading,
  setShowBarcode,
  setTicketInformation,
  isLoading,
}) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createdDate');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [winStatus, setWinStatus] = React.useState({
    label: 'Hamısı',
    value: 'Hamısı',
  });
  const [rows, setRows] = React.useState([]);
  const [skip, setSkip] = React.useState(0);
  const [take, setTake] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [sortField, setSortField] = React.useState('createdDate');

  const customerId = useSelector((state) => state.auth.id);
  const onGetTicket = (filter = {}) => {
    const {
      winStatus: { value },
    } = filter;
    setIsLoading(true);
    const query = paramsObjectToString({
      skip,
      take,
      sortField,
      customerId,
      orderBy: order === 'asc',
      ...(value !== 'Hamısı'
        ? {
            statusId:
              value === 'Pending'
                ? 0
                : value === 'Win'
                ? 2
                : value === 'Lost'
                ? 3
                : value === 'Cancel' && 4,
          }
        : {}),
    });
    axios
      .get(`tickets/filterForCustomer?${query}`)
      .then((r) => {
        const data = r.data.data.map((i) => ({
          id: i.id,
          name: i.productName,
          ticketNumber: i.ticketNumber,
          circulationNumber: i.circulationNumber,
          price: i.price,
          statusId: i.statusId,
          winningAmount: i.winningAmount,
          winningStatus: i.statusName,
          date: i.createdDate,
          winningPaidStatus: i.winningPaidStatus,
          paidDateTime: i.paidDateTime,
          customerNumber: i.customerNumber,
        }));
        setRows(data);
        setTotalCount(r.data.totalCount);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  React.useEffect(() => {
    onGetTicket({ winStatus });
  }, [winStatus, skip, sortField, order, take]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setSortField(property);
  };

  const handleChangePage = (e, page) => {
    const skip = page * take;
    setSkip(skip);
    setPage(page);
  };

  const useStyles = createUseStyles({
    TableCell: {
      padding: '14px 35px !important',
    },
    blue: {
      color: '#24A3FF',
    },
    red: {
      color: '#FF0009',
    },
    gray: {
      color: 'rgb(153, 153, 153)',
    },
    success: {
      color: '#1dd419',
    },
    warning: {
      color: 'orange',
    },
    button: {
      background: 'unset',
      '& svg': {
        width: '20px',
        height: '20px',
        color: '#0996ed',
      },
    },
  });

  const classes = useStyles();

  const winningStatusOptions = [
    { label: 'Hamısı', value: 'Hamısı' },
    { label: 'Uduşlu', value: 'Win' },
    { label: 'Uduşsuz', value: 'Lost' },
    { label: 'Gözləmədə', value: 'Pending' },
    { label: 'Ləğv edilib', value: 'Cancel' },
  ];

  function CustomSelect() {
    const winningStatusHandler = (e) => {
      setWinStatus(e);
    };

    return (
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="custom-select winning-status">
            <label className="custom-select__label">Bilet statusu</label>
            <Select
              options={winningStatusOptions}
              defaultValue={{ label: 'Hamısı' }}
              value={winStatus}
              onChange={winningStatusHandler}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%' }} className="custom-table">
      <CustomSelect />
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody sx={{ whiteSpace: 'nowrap' }}>
              {rows.map((row) => (
                <StyledTableRow tabIndex={-1} key={row.id}>
                  <TableCell padding="none" align="center">
                    {row.id}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    className={classes.TableCell}
                  >
                    {row.ticketNumber}
                    <button
                      onClick={() => {
                        setShowBarcode(true);
                        setTicketInformation({
                          barcode: row.ticketNumber,
                          ticketId: row.id,
                          customerNumber: row.customerNumber,
                          circulationNumber: row.circulationNumber,
                          createdDate: row.date,
                          price: row.price,
                        });
                      }}
                      className={classes.button}
                    >
                      <AiOutlineEye />
                    </button>
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {row.circulationNumber}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {row.price}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {row.winningAmount === 0 ? (
                      <span className="">-</span>
                    ) : (
                      `${row.statusId === 4 ? 0 : row.winningAmount}`
                    )}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <span
                      className={
                        row.statusId === 2
                          ? classes.blue
                          : row.statusId === 3
                          ? classes.red
                          : row.statusId === 0
                          ? classes.gray
                          : row.statusId === 4
                          ? classes.warning
                          : ''
                      }
                    >
                      {row.statusId === 0
                        ? 'Gözləmədə'
                        : row.statusId === 2
                        ? 'Uduşlu'
                        : row.statusId === 3
                        ? 'Uduşsuz'
                        : row.statusId === 4 && 'Ləğv edilib'}
                    </span>
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {format(new Date(row.date), 'dd.MM.yyyy HH:mm:ss')}
                  </TableCell>
                  <TableCell padding="none" align="center">
                    <span
                      className={
                        row.statusId === 2 && row.winningPaidStatus
                          ? classes.success
                          : row.statusId === 3
                          ? classes.red
                          : row.statusId === 2 && !row.winningPaidStatus
                          ? classes.gray
                          : ''
                      }
                    >
                      {row.winningPaidStatus ? (
                        'Ödənilmişdir'
                      ) : (
                        <span className="">-</span>
                      )}
                    </span>
                  </TableCell>
                  <TableCell padding="none" align="center">
                    {row.paidDateTime ? (
                      format(new Date(row.paidDateTime), 'dd.MM.yyyy HH:mm:ss')
                    ) : (
                      <span className="">-</span>
                    )}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <RenderIf condition={!rows.length && !isLoading}>
            <AlertComponent message="Biletiniz yoxdur." />
          </RenderIf>
        </TableContainer>
        <RenderIf condition={rows.length}>
          <ThemeProvider theme={theme}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={take}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
            />
          </ThemeProvider>
        </RenderIf>
      </Paper>
    </Box>
  );
}
