import {actions} from "modules/auth/_redux/authRedux";
import {useSelector, useDispatch} from "react-redux";
import {createSignalRContext} from "react-signalr/signalr";

import {useAuth} from "../hooks";

const SignalRContext = createSignalRContext();

const SignalRProvider = ({children}) => {
    const token = useAuth();

    const {id} = useSelector(state => state.auth);

    return (
        <SignalRContext.Provider
            connectEnabled={!!token}
            accessTokenFactory={() => token}
            dependencies={[token]}
            url={`https://api.topaznard.az/balanceHub?userId=${id}`}
        >
            <Wrapper>
                {children}
            </Wrapper>
        </SignalRContext.Provider>
    )
}

const Wrapper = ({children}) => {
    const dispatch = useDispatch();

    SignalRContext.useSignalREffect('SendMessage', () => {
        dispatch(actions.getProfile())
    }, [])

    return children
}

export default SignalRProvider;