import React, {useState, useEffect} from "react";

import axios from "axios";
import LottiePlayer from "components/lottie-player";
import diceAnimation from "components/lottie-player/animations/dice-animation.json";
import {Helmet} from "react-helmet-async";

import "./Sportsmen.css";

const Sportsmen = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const getSportsmen = () => {
        setIsLoading(true);
        axios
            .get('https://api.nards.club/api/nards/site/athletes')
            .then(({data}) => {
                setData(data?.data ?? [])
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        getSportsmen()
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>İdmançılar | Nard</title>
                <meta name="description" content="Nəticələr | Nard"/>
            </Helmet>
            <div className="sportsmen">
                <div className="sportsmen__container px-2 px-sm-4">
                    <h1 className="sportsmen__title">İdmançılar</h1>
                    {!isLoading ? (
                        <div className="sportsmen__list">
                            {!isLoading && data.map(item => (
                                <div key={item.id} className="sportsmen__item">
                                    <img src={`https://www.nards.club${item.avatar}`} alt={item.name}/>
                                    <div className="d-flex flex-column">
                                        <p className="sportsmen__item__title">{item.surname}</p>
                                        <p className="sportsmen__item__title">{item.name}</p>
                                        <p className="sportsmen__item__title">{item.patronymic}</p>
                                        <div className="sportsmen__item__stats">
                                            <div className="sportsmen__item__stat">
                                                <p>Oyunlar</p>
                                                <p>{item.games}</p>
                                            </div>
                                            <div className="sportsmen__item__stat">
                                                <p>Qalibiyyət</p>
                                                <p>{item.wins}</p>
                                            </div>
                                            <div className="sportsmen__item__stat">
                                                <p>Qalibiyyət ehtimalı</p>
                                                <p>{item.winsPercentage}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <LottiePlayer
                            animation={diceAnimation}
                            width="200px"
                            height="200px"
                        />
                    )}

                </div>
            </div>
        </React.Fragment>
    )
}

export default Sportsmen