import { useState } from 'react';

import Button from 'components/button/Button';
import { useFormik } from 'formik';
import useOtpTimer from 'hooks/useOtpTimer';
import { connect, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {toast} from "react-toastify";
import * as Yup from 'yup';

import { checkOtp } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import CustomProgress from '../components/CustomProgress';
import PinField from "../components/PinField";

function OtpVerification(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { isDisabled, resendOtp, time, isFinished } = useOtpTimer(120);

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const { phoneNumber } = useSelector(({ auth }) => ({
        phoneNumber: auth.phoneNumber?.replace(/[()+\s-]/g, ''),
    }));

    const formik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: Yup.object({
            otp: Yup.string().required('OTP kodu daxil edin'),
        }),
        onSubmit: (values) => {
            enableLoading();
            checkOtp({
                phoneNumber,
                otp: values.otp
            })
                .then(() => {
                    disableLoading();
                    props.setOtp(values.otp);
                    if(location.pathname === '/auth/forgot-password/otp-verification') {
                        navigate('/auth/forgot-password/new-password');
                    } else {
                        navigate('/auth/register/check-register');
                    }
                })
                .catch((err) => {
                    toast.error(err.response?.data?.message ?? 'Xəta baş verdi')
                    disableLoading();
                });
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} className="auth__check-register">
            <Link to="/" className="auth__logo">
                <img src="/media/topaz-nard-logo-black.svg" alt="Topaz" />
            </Link>
            <div className="auth__title">
                <p className="text-center">OTP verification</p>
            </div>
            <p className="text-center mb-3">
                <span className="auth__blue-text">{phoneNumber}</span> nömrəsinə otp kod göndərildi
            </p>
            <p className="auth__otp-text mb-4">
                {isFinished ? (
                    <>
                        OTP kod gəlməyib? <button type="button" disabled={isDisabled || time !== 0} onClick={resendOtp} className="auth__otp-text-underline">Yenidən göndər</button>
                    </>
                ) : time}
            </p>
            <PinField digit={6} onChange={formik.setFieldValue} field="otp"/>
            <div className="d-flex gap-3">
                <Button onClick={() => navigate(-1)} type="button" variant="secondary" className="auth__submit mt-4 w-100">
                    Geri qayıt
                </Button>
                <Button type="submit" className="auth__submit mt-4 w-100">
                    Təsdiqlə
                </Button>
            </div>
            {loading && (
                <div className="custom-progress">
                    <CustomProgress />
                </div>
            )}
        </form>
    );
}

export default connect(null, auth.actions)(OtpVerification);
