import HomeLayout from 'components/layout/HomeLayout';

import Sportsmen from '../modules/sportsmen';

const SportsmenPage = () => (
    <HomeLayout>
        <Sportsmen />
    </HomeLayout>
);

export default SportsmenPage;
