import {useState, useEffect} from 'react';

import axios from 'axios';

function useCirculations() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [skip, setSkip] = useState(0);
    const [take] = useState(6);
    const [totalCount, setTotalCount] = useState(0);

    async function getCirculation() {
        try {
            setLoading(true);
            const {
                    data: {data, totalCount},
                } = await axios.get(
                    `circulations/filter`,
                    {
                        params: {
                            skip, take, sortField: 'CirculationJackpot.Amount', isActive: true, isExpired: false
                        }
                    }
                )
            ;
            setData(data);
            setTotalCount(totalCount);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
            getCirculation()
        },[skip, take]
    );

    return {data, error, loading, skip, setSkip, take, totalCount, refetch: getCirculation};
}

export default useCirculations;
