import { useEffect } from 'react';

import axios from 'axios';
import { useFormik } from 'formik';
import { actions } from 'modules/auth/_redux/authRedux';
import { actions as scanActions } from 'modules/home/_redux/scanRedux';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import './ScanInput.css';

import { Keyboard } from '../icons';

const ScanInput = ({
  className = '',
  setVoucherAmount,
  setShowBalanceInfo,
  ...props
}) => {
  const dispatch = useDispatch();

  const { inputValue, isScanner, isDisabled } = useSelector(
    (state) => state.scan
  );

  const formik = useFormik({
    initialValues: {
      voucherNumber: '',
    },
    onSubmit: async ({ voucherNumber }, { resetForm, setSubmitting }) => {
      try {
        setSubmitting(true);
        const { data } = await axios.put('vouchers/consume', {
          voucherNumber,
        });
        setVoucherAmount(data);
        setShowBalanceInfo(true);
        dispatch(actions.getProfile());
        dispatch(scanActions.setRandomId(uuid()));
        resetForm();
      } catch (err) {
        toast.error(err.response?.data?.message ?? 'Xəta baş verdi.');
      } finally {
        setSubmitting(false);
        resetForm();
        dispatch(scanActions.resetScanner());
      }
    },
  });

  useEffect(() => {
    if (isScanner && inputValue.length === 16 && !isDisabled) {
      formik.setFieldValue('voucherNumber', inputValue);
    }
  }, [inputValue, isScanner]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={`ScanInput ${className}`}
      {...props}
    >
      <Keyboard />
      <input
        type="text"
        placeholder="Voucher kodu"
        {...formik.getFieldProps('voucherNumber')}
      />
      <button disabled={formik.isSubmitting} type="submit">
        vauçer oxut
      </button>
    </form>
  );
};

export default ScanInput;
