import React, { useState } from 'react';

import { Card, Close, Ticket } from 'components/icons';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import TopupBalanceCreditCard from './TopupBalanceCreditCard';
import TopupBalanceVoucher from './TopupBalanceVoucher';

const useStyles = createUseStyles({
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: 500,
    textAlign: 'center',
  },
  closeButton: {
    width: '36px',
    height: '36px',
    border: 'unset',
    borderRadius: '50%',
    position: 'absolute',
    right: '30px',
    top: '20px',
    background: '#1E1E1E',
    padding: 'unset',

    '& svg': {
      stroke: '#FFFFFF',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  text: {
    maxWidth: '474px',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  inputContainer: {
    padding: '12px 16px',
    borderRadius: '10px',
    background: '#F5F5F5',
    '& svg': {
      marginRight: '8px',
    },
  },
  input: {
    border: 'unset',
    background: 'unset',
    outline: 'unset',
  },
  form: {
    display: 'flex',
    gap: '12px',
  },
  button: {
    background: '#00AFF5',
    width: '100%',
  },
  cardText: {
    color: '#000000',
    maxWidth: '170px',
  },
  methods: {
    display: 'flex',
    gap: '20px',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  method: {
    background: 'rgba(245, 245, 245, 0.64)',
    borderRadius: '18px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    flexGrow: 1,
    border: '1px solid transparent',
    '@media (max-width: 768px)': {
      padding: '12px 16px',
    },
  },
  activeMethod: {
    borderColor: '#2098e1',
  },
  methodText: {
    color: '#a8a8a8',
  },
  activeMethodText: {
    color: '#2098e1',
  },
  dialog: {
    maxWidth: '450px',
  },
  body: {
    padding: '32px',
  },
});

export default function PaymentMethodModal({
  show,
  onHide,
  setVoucherAmount,
  setShowBalanceInfo,
}) {
  const [activeMethodIndex, setActiveMethodIndex] = useState(0);

  const methods = [
    {
      id: 1,
      title: 'Bank kartı ilə',
      icon: <Card />,
      form: <TopupBalanceCreditCard onHide={onHide} />,
    },
    {
      id: 2,
      title: 'Vauçer kod ilə',
      icon: <Ticket />,
      form: (
        <TopupBalanceVoucher
          setVoucherAmount={setVoucherAmount}
          setShowBalanceInfo={setShowBalanceInfo}
          onHide={onHide}
        />
      ),
    },
  ];

  const classes = useStyles();

  function handleClose() {
    onHide(false);
  }

  function handleClickMethod(index) {
    setActiveMethodIndex(index);
  }

  return (
    <React.Fragment>
      <Modal
        centered
        show={show}
        onHide={onHide}
        dialogClassName={classes.dialog}
      >
        <Modal.Body className={classes.body}>
          <button
            type="button"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <Close />
          </button>
          <div className={classes.container}>
            <p className={classes.title}>Balans artır</p>
            <div className={classes.methods}>
              {methods.map((method, index) => (
                <button
                  key={method.id}
                  onClick={() => handleClickMethod(index)}
                  className={`${classes.method} ${
                    index === activeMethodIndex ? classes.activeMethod : ''
                  }`}
                >
                  {method.icon}
                  <span
                    className={`${classes.methodText} ${
                      index === activeMethodIndex
                        ? classes.activeMethodText
                        : ''
                    }`}
                  >
                    {method.title}
                  </span>
                </button>
              ))}
              {methods[activeMethodIndex].form}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
