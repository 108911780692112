import HomeLayout from 'components/layout/HomeLayout';

import Home from '../modules/home';

const HomePage = () => (
  <HomeLayout>
    <Home />
  </HomeLayout>
);

export default HomePage;
