import React from 'react';

import {Close} from "components/icons";
import {Check} from "components/icons";
import {Modal} from 'react-bootstrap';
import {createUseStyles} from 'react-jss'


const useStyles = createUseStyles({
    title: {
        fontSize: "32px",
        textAlign: "center",
        lineHeight: "42px",
        fontWeight: 500
    },
    closeButton: {
        width: "36px",
        height: "36px",
        border: "unset",
        borderRadius: "50%",
        position: "absolute",
        right: "30px",
        top: "20px",
        background: "#1E1E1E",
        padding: "unset",

        "& svg": {
            stroke: "#FFFFFF"
        }
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px"
    },
    text: {
        maxWidth: "318px",
        fontSize: "16px",
        lineHeight: "24px",
        textAlign: "center"
    },
    modalBody: {
        padding: "50px"
    }
});

export default function BalanceInfoModal({show, onHide, voucherAmount}) {
    const classes = useStyles();

    function handleClose() {
        onHide(false);
    }

    return (
        <React.Fragment>
            <Modal centered show={show} onHide={onHide}>
                <Modal.Body className={classes.modalBody}>
                    <button type="button" onClick={handleClose} className={classes.closeButton}><Close/></button>
                    <div className={classes.container}>
                        <Check/>
                        <p className={classes.title}>Balansınıza {voucherAmount} AZN köçürüldü</p>
                        <p className={classes.text}>Növbəti vauçeri ancaq balans 0 - sıfır
                            olduğu halda istifadə edə bilərsiniz
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}