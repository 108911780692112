import React from "react";

function CalendarCheck(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
            {...props}
        >
            <path
                fill="#A8A8A8"
                d="M7.3 11.99L5.164 9.854l.723-.723L7.3 10.544l2.813-2.813.723.723L7.3 11.99zm-3.762 2.343c-.337 0-.622-.116-.855-.35a1.163 1.163 0 01-.35-.855V4.205c0-.337.117-.622.35-.855.233-.233.518-.35.855-.35h.923V1.59h1.026V3h5.051V1.59h1V3h.923c.337 0 .622.117.855.35.234.233.35.518.35.855v8.923c0 .337-.116.622-.35.855-.233.234-.518.35-.855.35H3.538zm0-1h8.923a.196.196 0 00.141-.064.196.196 0 00.064-.14V6.871H3.333v6.256c0 .052.021.098.064.141.043.043.09.064.141.064zm-.205-7.461h9.333V4.205a.196.196 0 00-.064-.14.196.196 0 00-.14-.065H3.537a.196.196 0 00-.14.064.196.196 0 00-.065.141v1.667z"
            ></path>
        </svg>
    );
}

export default CalendarCheck;
