import React from "react";

function PDF() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M37 19v-5.942a.979.979 0 00-.293-.763l-8-8a.979.979 0 00-.763-.293H10a5 5 0 00-5 5v30a5 5 0 005 5h22a5 5 0 005-5V33h-2v6.002a3 3 0 01-3 3H10a3 3 0 01-3-3v-30a3 3 0 013-3h17v7a1 1 0 001 1h7V19h2zm-8-6.998V7.416l4.586 4.586H29z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M38 21v10h1.811v-4.243h3.036v-1.643H39.81v-2.428H43V21h-5zM19.49 21.204v9.8h1.967v-3.5h.887c2.631 0 3.74-1.12 3.74-3.15 0-1.89-1.247-3.15-3.74-3.15H19.49zm1.967 4.76v-3.22h.887c1.108 0 1.662.56 1.662 1.61s-.554 1.61-1.662 1.61h-.887zM28 21v9.8h3.061c4.46 0 5.278-2.758 5.278-4.9 0-4.522-3.56-4.9-5.278-4.9H28zm1.967 1.652h1.122c.595 0 3.172.112 3.172 3.248s-2.577 3.248-3.172 3.248h-1.122v-6.496z"
            ></path>
        </svg>
    );
}

export default PDF;
