import { Dropdown } from 'antd';
import { Dots } from 'components/icons';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles({
  button: {
    color: '#FFFFFF',
    background: '#292929',
    padding: '8px 12px',
    marginLeft: '12px',
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: '0px 5px 25px -10px rgba(0,0,0,0.75)',
  },
});

const NavLinkDropdown = ({ className = '' }) => {
  const navigate = useNavigate();

  const classes = useStyles();

  const items = [
    {
      label: <div onClick={() => navigate('/results')}>Nəticələr</div>,
      key: '2',
    },
    {
      label: <div onClick={() => navigate('/sportsmen')}>İdmançılar</div>,
      key: '3',
    },
    // {
    //   label: <div onClick={() => navigate('/documents')}>Sənədlər</div>,
    //   key: '4',
    // },
    {
      label: <div onClick={() => navigate('/partners')}>Partnyorlar</div>,
      key: '5',
    },
    {
      label: (
        <div className="text-danger" onClick={() => navigate('/Live')}>
          Live
        </div>
      ),
      key: '6',
    },
  ];

  return (
    <Dropdown menu={{ items }} className={className}>
      <div className={classes.button}>
        Digər <Dots />
      </div>
    </Dropdown>
  );
};

export default NavLinkDropdown;
