import React from "react";

import {Link, useMatch, useResolvedPath} from "react-router-dom";

const ActiveLink = ({to, className, activeClassName, ...props}) => {
    const resolved = useResolvedPath(to);

    const match = useMatch({path: resolved.pathname, end: true});

    return (
        <Link to={to} {...props} className={`${className} ${match ? activeClassName : ""}`}>
            {props.children}
        </Link>
    );
}

export default ActiveLink