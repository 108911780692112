const ErrorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="158"
    height="158"
    fill="none"
    viewBox="0 0 158 158"
  >
    <path
      fill="#F50000"
      d="M79 111.917q2.797 0 4.69-1.893t1.893-4.691q0-2.797-1.893-4.69T79 98.75q-2.8 0-4.691 1.893-1.893 1.893-1.893 4.69t1.893 4.691q1.892 1.893 4.69 1.893m-6.584-26.334h13.167v-39.5H72.416zm6.583 59.25q-13.66 0-25.675-5.184t-20.902-14.072q-8.887-8.887-14.072-20.902T13.166 79t5.184-25.675q5.185-12.015 14.072-20.902t20.902-14.072T79 13.167q13.661 0 25.675 5.184t20.902 14.072 14.072 20.902Q144.833 65.34 144.833 79t-5.185 25.675q-5.184 12.015-14.072 20.902t-20.902 14.072q-12.015 5.184-25.675 5.184m0-13.166q22.055 0 37.361-15.306 15.306-15.307 15.306-37.36 0-22.056-15.306-37.361Q101.054 26.334 79 26.333q-22.055 0-37.361 15.307Q26.333 56.946 26.333 79t15.306 37.361 37.36 15.306"
    ></path>
  </svg>
);

export default ErrorIcon;
