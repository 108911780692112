import HomeLayout from 'components/layout/HomeLayout';

import {BalanceHistory} from "../modules/balance-history";

const BronPage = () => (
    <HomeLayout>
        <BalanceHistory />
    </HomeLayout>
);

export default BronPage;
