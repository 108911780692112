import React, { useState, useEffect, createRef, memo } from 'react';
import './style/style.css';

function PinField({ digit, field, onChange, ...props }) {
  const [inputValues, setInputValues] = useState([]);

  function handleChange(e, index) {
    const regex = new RegExp(/^(?!\s*$)\d*$/);

    const { value } = e.target;
    const number = value.charAt(value.length - 1);
    const tempState = inputValues.map((item, idx) => {
      if (idx === index && (regex.test(number) || !number)) {
        return {
          ...item,
          value: number,
        };
      } else {
        return item;
      }
    });
    if (index !== inputValues.length - 1 && regex.test(value)) {
      inputValues[index + 1].ref.current.focus();
    }

    if (!value && index !== 0) {
      inputValues[index - 1].ref.current.focus();
    }
    setInputValues(tempState);
  }

  useEffect(() => {
    if (digit) {
      const values = Array(digit)
        .fill({})
        .map((_) => ({ value: null, ref: createRef() }));
      setInputValues(values);
    }
  }, [digit]);

  useEffect(() => {
    const value = inputValues.map((item) => item.value).join('');
    onChange && value.length === digit && onChange(field, value);
  }, [inputValues]);

  return (
    <div className="pin-wrapper">
      {inputValues.map(({ ref, value }, index) => (
        <input
          key={index}
          ref={ref}
          type="text"
          value={value || ''}
          inputMode="numeric"
          onChange={(e) => handleChange(e, index)}
          onKeyUp={(e) => {
            if(e.key === 'Backspace' && index > 0 && !e.target.value) {
              inputValues[index - 1].ref.current.focus();
            }
          }}
          {...props}
        />
      ))}
    </div>
  );
}

export default memo(PinField);
