import {forwardRef} from "react";

import {Checkbox} from "@mui/material";
import {Divider} from "@mui/material";
import {format} from "date-fns";
import "./Print.css";


const Print = forwardRef(({circulationData}, ref) => {
    return (
        <div ref={ref} className="print-ticket__container">
            <div className="print-ticket p-4">
                <p className="fw-bold text-center mb-4">{circulationData?.productName} {circulationData?.code}</p>
                <div className="d-flex gap-4 justify-content-between">
                    <div className="d-flex flex-column gap-2">
                        <span className="print-ticket__text-black print-ticket__text-sm">Cekpot məbləği</span>
                        <span
                            className="print-ticket__text-black print-ticket__text-sm">{circulationData?.circulationJackpot?.amount} AZN</span>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <span className="print-ticket__text-black print-ticket__text-sm">Turun başlama tarixi</span>
                        <span
                            className="print-ticket__text-back print-ticket__text-sm">{circulationData.expiredDate && format(new Date(circulationData.expiredDate), "dd-MM-yyyy HH:mm")}</span>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <span className="print-ticket__text-black print-ticket__text-sm">Turun bitmə tarixi</span>
                        <span
                            className="print-ticket__text-black print-ticket__text-sm">{circulationData.resultedDate && format(new Date(circulationData.resultedDate), "dd-MM-yyyy HH:mm")}</span>
                    </div>
                </div>
                <div className="d-flex flex-column gap-1 mt-3">
                    {circulationData?.circulationGames?.map((game, index) => (
                        <div key={game.id}>
                            <div className="d-flex flex-column">
                                <div className="d-flex gap-1">
                                    <span className="fw-bold print-ticket__text-sm">{index + 1}.</span>
                                    <div className="d-flex flex-column gap-2">
                                        <span className="print-ticket__text-sm">{game?.hostClubName} - {game?.guestClubName}</span>
                                        <span className="print-ticket__text-sm">{game.startDate && format(new Date(game.startDate), "dd-MM-yyyy HH:mm")}</span>
                                    </div>
                                </div>
                                <div className="d-flex gap-2">
                                    <div className="d-flex gap-1 align-items-center">1 <Checkbox/></div>
                                    <div className="d-flex gap-1 align-items-center">X <Checkbox /></div>
                                    <div className="d-flex gap-1 align-items-center">2 <Checkbox /></div>
                                </div>
                            </div>
                            <Divider/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
})

export default Print;