import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const actionTypes = {
  SetGameId: '[Set Game Id] action',
  SetShowWarningModal: '[Set show warning modal] action',
};

const initialState = {
  gameId: 2,
  showWarningModal: true,
};

export const reducer = persistReducer(
  { storage, key: 'game', whitelist: ['showWarningModal'] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetGameId: {
        const { gameId } = action.payload;
        return {
          ...state,
          gameId,
        };
      }

      case actionTypes.SetShowWarningModal: {
        const { value } = action.payload;
        return {
          ...state,
          showWarningModal: Boolean(value),
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setGameId: (gameId) => ({
    type: actionTypes.SetGameId,
    payload: { gameId },
  }),
  setShowWarningModal: (value) => ({
    type: actionTypes.SetShowWarningModal,
    payload: { value },
  }),
};
