import React from "react";

function Trophy() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
        >
            <path
                fill="#E8EAED"
                d="M16 14.667c.733 0 1.36-.261 1.883-.783.522-.523.783-1.15.783-1.884 0-.733-.261-1.36-.783-1.883a2.568 2.568 0 00-1.884-.783c-.733 0-1.36.26-1.883.783A2.568 2.568 0 0013.333 12c0 .734.26 1.361.783 1.884.522.522 1.15.783 1.883.783zm-5.744 12.667v-2h4.743v-4.57a6.651 6.651 0 01-3-1.428 5.941 5.941 0 01-1.851-2.71c-1.538-.183-2.837-.836-3.895-1.959-1.058-1.123-1.587-2.457-1.587-4V9.334c0-.546.197-1.015.591-1.41a1.924 1.924 0 011.409-.59h3.103V4.667H22.23v2.667h3.103c.545 0 1.015.197 1.409.59.394.395.59.864.59 1.41v1.333c0 1.543-.528 2.877-1.587 4-1.058 1.123-2.356 1.776-3.894 1.959a5.94 5.94 0 01-1.852 2.71 6.65 6.65 0 01-3 1.428v4.57h4.744v2H10.256zm-.487-12.857V9.334H6.666v1.333c0 .93.291 1.747.874 2.45a3.941 3.941 0 002.229 1.36zm6.23 4.37c1.171 0 2.165-.409 2.981-1.225.816-.816 1.224-1.81 1.224-2.98V6.666h-8.41v7.974c0 1.171.409 2.165 1.225 2.981.816.816 1.81 1.225 2.98 1.225zm6.231-4.37a3.94 3.94 0 002.228-1.36c.583-.703.875-1.52.875-2.45V9.334H22.23v5.143z"
            ></path>
        </svg>
    );
}

export default Trophy;
