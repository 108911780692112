import React from "react";

const Crocusoft = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="92" height="20" viewBox="0 0 92 20" {...props}>
        <g clipPath="url(#clip0_1992_644)">
            <path d="M9.1819 0H9.67233L16.3414 2.87407C16.5081 2.94629 16.6527 3.06435 16.7592 3.2154C16.8658 3.36644 16.9304 3.54468 16.9459 3.7307C16.9614 3.91672 16.9272 4.10339 16.8472 4.27039C16.7671 4.43738 16.6442 4.57829 16.4918 4.67778L13.0409 6.93333C12.9335 7.00247 12.8166 7.03704 12.6901 7.03704H6.0854C5.95653 7.03704 5.8384 7.00123 5.731 6.92963L2.31949 4.71852C2.16233 4.61753 2.0353 4.47361 1.95234 4.30256C1.86938 4.13152 1.83371 3.93997 1.84925 3.74897C1.86479 3.55797 1.93094 3.37491 2.04042 3.21991C2.1499 3.06491 2.29848 2.94396 2.46984 2.87037L9.1819 0Z" fill="#6742C1"/>
            <path d="M86.9414 8.38519C87.0655 8.13087 87.0667 7.85309 86.945 7.55185C86.8829 7.39877 86.933 7.32099 87.0953 7.31852L88.1406 7.31482C88.3124 7.31235 88.3983 7.22346 88.3983 7.04815V5.3C88.3983 5.24074 88.4282 5.21111 88.4878 5.21111H90.113C90.2109 5.21111 90.2598 5.26173 90.2598 5.36296L90.2491 7.05926C90.2467 7.2321 90.3302 7.31852 90.4997 7.31852L91.5485 7.31482C91.6655 7.31482 91.7239 7.37531 91.7239 7.4963L91.7275 8.54445C91.7275 8.68272 91.6607 8.75062 91.5271 8.74815L90.5605 8.74445C90.3529 8.74198 90.2491 8.84815 90.2491 9.06296C90.2515 10.6086 90.255 12.1778 90.2598 13.7704C90.2634 14.4704 90.9865 14.5778 91.5306 14.4407C91.6738 14.4062 91.7478 14.4654 91.7526 14.6185L91.7705 15.6593C91.7712 15.7265 91.7495 15.7919 91.7091 15.8445C91.6687 15.8971 91.612 15.9337 91.5485 15.9481C89.5188 16.4222 88.3482 15.6296 88.3804 13.4407C88.4019 11.9642 88.4019 10.4321 88.3804 8.84445C88.3804 8.76543 88.3423 8.72593 88.2659 8.72593L85.2553 8.74074C85.1694 8.74074 85.1264 8.78642 85.1264 8.87778L85.1372 15.8074C85.1372 15.884 85.1002 15.9222 85.0262 15.9222L83.3938 15.9259C83.3606 15.9259 83.3287 15.9123 83.3052 15.888C83.2817 15.8636 83.2685 15.8307 83.2685 15.7963V9.02222C83.2685 8.98495 83.2614 8.94804 83.2475 8.91364C83.2336 8.87925 83.2132 8.84805 83.1876 8.82187C83.1619 8.79568 83.1315 8.77502 83.0981 8.7611C83.0647 8.74717 83.0289 8.74025 82.9929 8.74074L82.1552 8.75926C82.0574 8.76173 82.0073 8.71111 82.0049 8.60741L81.9941 7.49259C81.9941 7.37408 82.0502 7.31482 82.1624 7.31482H83.0537C83.2351 7.31482 83.3163 7.22222 83.2972 7.03704C83.0251 4.43334 84.7398 3.11852 87.149 3.67408C87.2158 3.68889 87.248 3.73087 87.2457 3.8L87.2063 5.11111C87.2039 5.18766 87.1669 5.21729 87.0953 5.2C85.5775 4.85556 85.0047 5.6963 85.1264 7.08889C85.1384 7.23951 85.2171 7.31482 85.3627 7.31482L86.5977 7.31852C86.7505 7.31852 86.8256 7.39753 86.8232 7.55556L86.8125 8.35185C86.8077 8.55926 86.8507 8.57037 86.9414 8.38519Z" fill=""/>
            <path d="M30.0807 14.437C31.7035 14.558 32.5973 13.8457 32.7619 12.3C32.7739 12.1938 32.8312 12.1407 32.9338 12.1407L34.6377 12.1444C34.6498 12.1449 34.6617 12.1479 34.6726 12.153C34.6835 12.1582 34.6933 12.1655 34.7013 12.1745C34.7093 12.1835 34.7154 12.194 34.7193 12.2054C34.7231 12.2168 34.7246 12.2288 34.7237 12.2407C34.5447 14.2778 33.1593 15.8185 31.2083 16.0222C26.5618 16.5148 25.4986 12.5667 25.9103 8.72593C26.1084 6.89136 26.8589 5.57778 28.1619 4.78519C29.6296 3.89259 31.867 3.97407 33.2237 5.05926C34.0876 5.74815 34.5888 6.74568 34.7272 8.05185C34.7288 8.06589 34.7274 8.08009 34.7232 8.09354C34.719 8.10699 34.7122 8.11936 34.703 8.12986C34.6939 8.14036 34.6827 8.14873 34.6702 8.15444C34.6577 8.16015 34.6442 8.16305 34.6306 8.16296L32.8658 8.15926C32.818 8.15926 32.7906 8.13457 32.7834 8.08519C32.5949 6.43333 31.7298 5.68395 30.1881 5.83704C28.8755 5.9679 28.113 6.8321 27.9006 8.42963C27.6679 10.1778 27.4424 14.2407 30.0807 14.437Z" fill=""/>
            <path d="M0 7.53337V6.8556C0.36275 6.07782 0.92358 5.89634 1.68249 6.31115C2.81608 6.93337 3.93416 7.54942 5.03673 8.1593C5.16065 8.22703 5.26262 8.32995 5.33027 8.4556C6.27772 10.184 7.30392 12.0186 8.40887 13.9593C8.61889 14.3297 8.71793 14.7395 8.70599 15.1889C8.67735 16.421 8.63201 17.6581 8.56996 18.9C8.56068 19.0905 8.50115 19.2748 8.39783 19.4328C8.29452 19.5907 8.15136 19.7164 7.98389 19.7962C7.81642 19.876 7.63103 19.9068 7.44785 19.8852C7.26466 19.8637 7.09067 19.7907 6.94475 19.6741L1.39611 15.2445C1.13907 15.0404 0.969446 14.74 0.92358 14.4074L0 7.53337Z" fill="#6742C1"/>
            <path d="M12.8404 9.58507C13.1482 9.0147 13.4454 8.32951 14.0217 8.0147C15.1219 7.40976 16.2221 6.80359 17.3223 6.19618C17.4825 6.10725 17.6634 6.06598 17.8449 6.07692C18.0265 6.08786 18.2016 6.15058 18.3511 6.25818C18.5007 6.36578 18.6187 6.51409 18.6924 6.68677C18.766 6.85946 18.7923 7.04983 18.7685 7.23692L17.8342 14.4591C17.7928 14.7702 17.6359 15.0509 17.3974 15.2406L11.813 19.6962C11.6697 19.8108 11.4986 19.8822 11.3186 19.9027C11.1385 19.9232 10.9565 19.8919 10.7926 19.8123C10.6287 19.7326 10.4891 19.6077 10.3894 19.4513C10.2896 19.2949 10.2335 19.1131 10.2272 18.9258C10.1866 17.7184 10.1389 16.5048 10.084 15.2851C10.0601 14.769 10.183 14.2814 10.4527 13.8221C11.276 12.4147 12.0719 11.0024 12.8404 9.58507Z" fill="#6742C1"/>
            <path d="M37.9845 8.02959C38.5644 7.32959 39.3018 7.01848 40.2004 7.20737C40.2467 7.21752 40.2884 7.24437 40.3183 7.28345C40.3483 7.32252 40.3648 7.37148 40.365 7.42218V8.87403C40.365 9.01724 40.297 9.07897 40.161 9.05922C39.2983 8.937 37.8628 9.13329 37.8592 10.3444C37.8544 12.1666 37.8604 13.9802 37.8771 15.7851C37.8771 15.8765 37.8329 15.9222 37.7446 15.9222H36.1194C36.0359 15.9222 35.9941 15.879 35.9941 15.7926V7.49255C35.9941 7.37403 36.0502 7.31478 36.1624 7.31478H37.6086C37.7399 7.31478 37.8055 7.38391 37.8055 7.52218V7.96292C37.8055 8.15058 37.8652 8.1728 37.9845 8.02959Z" fill=""/>
            <path d="M44.4618 16.1555C43.4194 16.1461 42.4237 15.6598 41.6937 14.8037C40.9638 13.9476 40.5594 12.7918 40.5695 11.5905C40.5797 10.3893 41.0035 9.24091 41.7477 8.39813C42.492 7.55535 43.4957 7.08716 44.5382 7.09657C45.5806 7.10598 46.5763 7.59221 47.3062 8.4483C48.0361 9.30439 48.4405 10.4602 48.4304 11.6615C48.4203 12.8628 47.9965 14.0111 47.2522 14.8539C46.5079 15.6967 45.5042 16.1649 44.4618 16.1555ZM44.495 14.6001C45.039 14.6011 45.5612 14.2883 45.9468 13.7305C46.3325 13.1728 46.5498 12.4158 46.5512 11.626C46.5518 11.235 46.4994 10.8477 46.3969 10.4862C46.2945 10.1247 46.1439 9.79618 45.9539 9.51932C45.7639 9.24246 45.5382 9.02271 45.2896 8.87262C45.0409 8.72252 44.7743 8.64502 44.505 8.64454C43.961 8.64356 43.4387 8.95635 43.0531 9.51409C42.6675 10.0718 42.4501 10.8289 42.4488 11.6186C42.4481 12.0097 42.5005 12.397 42.603 12.7584C42.7054 13.1199 42.856 13.4485 43.046 13.7253C43.236 14.0022 43.4618 14.2219 43.7104 14.372C43.959 14.5221 44.2256 14.5996 44.495 14.6001Z" fill=""/>
            <path d="M54.706 10.1888C54.5771 8.77402 52.9412 8.28884 51.9782 9.09624C50.779 10.1037 50.7575 14.1629 52.6727 14.4888C53.6941 14.6617 54.3659 14.2876 54.6881 13.3666C54.7597 13.1666 54.8969 13.0678 55.0998 13.0703L56.1952 13.074C56.4434 13.0765 56.5352 13.2012 56.4708 13.4481C55.5436 17.0037 50.4819 16.9999 49.5296 13.3666C48.8853 10.8999 49.5082 7.83698 52.2145 7.2555C54.2979 6.80735 56.2954 7.89254 56.4744 10.1925C56.4863 10.3259 56.4278 10.3925 56.299 10.3925L54.9315 10.3999C54.7931 10.4024 54.7179 10.332 54.706 10.1888Z" fill=""/>
            <path d="M69.9272 14.537C71.2696 14.1963 70.8508 12.7481 69.6659 12.5C68.9022 12.3395 68.1695 12.116 67.4679 11.8296C65.7675 11.1333 65.4489 9.06293 66.8915 7.91107C68.0478 6.99256 69.7446 6.937 71.0512 7.55922C71.8937 7.96169 72.3984 8.71601 72.5655 9.82219C72.5665 9.82959 72.566 9.83714 72.5639 9.84431C72.5619 9.85148 72.5584 9.85811 72.5536 9.86374C72.5488 9.86937 72.5429 9.87387 72.5363 9.87692C72.5297 9.87998 72.5226 9.88152 72.5154 9.88144L70.7935 9.91848C70.7688 9.91941 70.7447 9.91182 70.7252 9.89698C70.7056 9.88214 70.6918 9.86096 70.6861 9.837C70.4069 8.75305 69.6981 8.38144 68.5597 8.72219C67.5037 9.037 67.5753 10.3407 68.6635 10.6111C69.506 10.821 70.2816 11.0506 70.9904 11.3C72.9807 12 73.1597 14.6222 71.309 15.5814C69.2792 16.6333 66.0575 16.1185 65.6995 13.3185C65.6923 13.2642 65.7162 13.2358 65.7711 13.2333L67.3748 13.2148C67.4297 13.2148 67.4643 13.2419 67.4786 13.2963C67.8175 14.4518 68.6337 14.8654 69.9272 14.537Z" fill=""/>
            <path d="M77.4063 16.1554C76.3639 16.1441 75.369 15.656 74.6405 14.7986C73.912 13.9412 73.5096 12.7847 73.5218 11.5834C73.5339 10.3821 73.9597 9.23457 74.7054 8.39314C75.451 7.5517 76.4556 7.08534 77.498 7.09664C78.5404 7.10793 79.5352 7.59597 80.2637 8.45338C80.9922 9.31079 81.3946 10.4673 81.3825 11.6686C81.3703 12.8699 80.9446 14.0174 80.1989 14.8589C79.4532 15.7003 78.4487 16.1667 77.4063 16.1554ZM77.4471 14.6001C77.7169 14.6006 77.9843 14.5241 78.2338 14.3751C78.4834 14.2261 78.7103 14.0074 78.9015 13.7316C79.0928 13.4558 79.2447 13.1282 79.3486 12.7676C79.4524 12.4069 79.5063 12.0203 79.5069 11.6297C79.5076 11.2391 79.4551 10.8523 79.3524 10.4913C79.2498 10.1303 79.099 9.80215 78.9086 9.52564C78.7183 9.24912 78.4921 9.02965 78.2431 8.87974C77.994 8.72983 77.727 8.65242 77.4571 8.65193C77.1873 8.65145 76.92 8.72789 76.6704 8.8769C76.4209 9.02591 76.194 9.24457 76.0027 9.5204C75.8114 9.79622 75.6595 10.1238 75.5557 10.4845C75.4518 10.8451 75.398 11.2317 75.3973 11.6223C75.3967 12.0129 75.4492 12.3997 75.5518 12.7607C75.6545 13.1217 75.8053 13.4499 75.9956 13.7264C76.186 14.0029 76.4121 14.2224 76.6612 14.3723C76.9102 14.5222 77.1773 14.5996 77.4471 14.6001Z" fill=""/>
            <path d="M62.4567 15.2851C61.9316 15.7962 61.2992 16.0616 60.5594 16.0814C58.6048 16.1382 57.6156 15.0925 57.5918 12.9443C57.5751 11.3098 57.5727 9.47643 57.5846 7.44433C57.5846 7.35791 57.6264 7.3147 57.7099 7.3147H59.3351C59.4067 7.3147 59.4425 7.35173 59.4425 7.42581C59.4377 9.0505 59.4413 10.8925 59.4532 12.9517C59.4604 14.1295 60.0117 14.5703 61.1035 14.4888C62.1416 14.4098 62.6524 13.8382 62.6357 12.774C62.6046 10.8826 62.5999 9.09988 62.6213 7.42581C62.6213 7.39634 62.6327 7.36808 62.6528 7.34724C62.6729 7.3264 62.7002 7.3147 62.7287 7.3147H64.3647C64.4339 7.3147 64.4685 7.35173 64.4685 7.42581L64.4721 15.7221C64.4721 15.8604 64.4064 15.9283 64.2752 15.9258L62.8218 15.9221C62.7049 15.9196 62.6488 15.8579 62.6536 15.7369L62.6643 15.3851C62.6738 15.1777 62.6046 15.1443 62.4567 15.2851Z" fill=""/>
            <path d="M7.36703 8.1482L11.4193 8.1519C11.5165 8.15176 11.612 8.17821 11.6961 8.22855C11.7803 8.2789 11.85 8.35137 11.8984 8.4386C11.9467 8.52583 11.9719 8.62474 11.9714 8.72529C11.9709 8.82584 11.9447 8.92446 11.8954 9.01116L9.85854 12.6334C9.80998 12.7195 9.74047 12.7909 9.65694 12.8405C9.5734 12.8901 9.47876 12.9162 9.38243 12.9162C9.28611 12.9162 9.19146 12.8901 9.10793 12.8405C9.02439 12.7909 8.95488 12.7195 8.90633 12.6334L6.88734 9.00375C6.83885 8.91687 6.81338 8.8183 6.81348 8.71798C6.81358 8.61766 6.83925 8.51914 6.8879 8.43237C6.93655 8.34559 7.00647 8.27362 7.09059 8.22373C7.17471 8.17384 7.27006 8.14779 7.36703 8.1482Z" fill="#E83F4D"/>
        </g>
        <defs>
            <clipPath id="clip0_1992_644">
                <rect width="92" height="20" fill=""/>
            </clipPath>
        </defs>
    </svg>
)

export default Crocusoft