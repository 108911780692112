import { useEffect, useState } from 'react';

import axios from 'axios';
import Button from 'components/button/Button';
import Checkbox from 'components/Checkbox';
import RenderIf from 'components/RenderIf';
import format from 'date-fns/format';
import CustomProgress from 'modules/auth/components/CustomProgress';
import { connect, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import * as playTicketsRedux from '../home/_redux/PlayTicketsRedux';
import './BronUpdate.css';

function BronUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [columnPrice, setColumnPrice] = useState();
  const [loading, setLoading] = useState(false);
  const [ticketGames, setTicketGames] = useState([]);
  const [circulationData, setCirculationData] = useState([]);
  const [circulationId, setCirculationId] = useState('');
  const [ticketPrice, setTicketPrice] = useState('');
  const [exactTicketsCount, setExactTicketsCount] = useState(0);

  const amount = !ticketPrice ? columnPrice : ticketPrice;

  const { isAuth } = useSelector(({ auth }) => ({
    isAuth: auth.token !== undefined,
  }));

  function getExactTicketsCount(ticketGames, circulationId) {
    axios({
      url: `tickets/exactTicketsCount`,
      method: 'POST',
      data: {
        ticketGames,
        circulationId,
      },
    }).then(({ data }) => {
      setExactTicketsCount(data);
    });
  }

  useEffect(() => {
    async function getVoidTicket() {
      setLoading(true);
      try {
        const { data: voidTicketData } = await axios.get(
          `voidTickets/byId?id=${id}`
        );
        const { data: circulationData } = await axios.get(
          `circulations/circulationGames?id=${voidTicketData?.circulationId}`
        );
        setData(voidTicketData?.ticketGames);
        setCirculationData(circulationData?.games);
        setCirculationId(voidTicketData?.circulationId);
        setColumnPrice(circulationData?.columnPrice);
        const formattedVoidTicketData = voidTicketData.ticketGames.map((g) => ({
          gameId: g.gameId,
          option1: g.options.option1,
          optionX: g.options.optionX,
          option2: g.options.option2,
        }));
        setTicketGames(formattedVoidTicketData);
        getExactTicketsCount(
          formattedVoidTicketData,
          voidTicketData?.circulationId
        );
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
    getVoidTicket();
  }, [id]);

  const handleChange = (e, id) => {
    const {
      target: { name, checked },
    } = e;
    const checkbox = ticketGames.map((game) =>
      game.gameId === id ? { ...game, [name]: checked } : game
    );
    setTicketGames(checkbox);

    const ticketPricesData = [];
    let allFalseArr = [];
    checkbox.forEach((gameObj) => {
      let totalCount = 0;
      let oneChecked = false;
      for (const options in gameObj) {
        if (options === 'gameId') continue;
        if (gameObj[options] === true) {
          totalCount++;
          oneChecked = true;
        }
      }
      if (totalCount === 0) {
        totalCount++;
      }
      ticketPricesData.push(totalCount);
      allFalseArr.push(oneChecked);
    });
    const multipliedValue =
      ticketPricesData.reduce((a, b) => a * b) * columnPrice;
    setTicketPrice(multipliedValue.toFixed(2));
    if (!allFalseArr.some((item) => item === false)) {
      getExactTicketsCount(checkbox, circulationId);
    } else {
      setExactTicketsCount(0);
    }
  };

  const InfoToast = () => (
    <div>
      <h2>Turdakı bütün oyunlar üçün proqnoz verilməlidir.</h2>
    </div>
  );

  const ErrToast = () => (
    <div>
      <h2>Tur artıq başlayıb.Əməliyyat uğursuzdur.</h2>
    </div>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let allFalseArr = [];

    ticketGames.forEach((gameObj) => {
      let oneChecked = false;
      for (const option in gameObj) {
        if (option === 'gameId') continue;
        if (gameObj[option] === true) {
          oneChecked = true;
        }
      }
      allFalseArr.push(oneChecked);
    });

    if (allFalseArr.filter((item) => item === false).length > 0) {
      toast.info(InfoToast);
    } else if (isAuth) {
      setLoading(true);
      axios
        .put(`voidTickets/update`, {
          id: id,
          ticketGames,
          type: 0,
        })
        .then(() => {
          navigate('/my-bron-tickets');
          setLoading(false);
        })
        .catch(() => {
          toast.error(ErrToast);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <div className="bron-update">
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="bron-update__container">
              <div className="bron-update__title">
                <span>Yeni bron</span>
              </div>
              <div className="wrapper-items">
                <div className="circulation__games-header">
                  <div></div>
                  <span>1</span>
                  <span>X</span>
                  <span>2</span>
                </div>
                <div className="circulation__games-body">
                  {data?.map((item, index) => (
                    <div className="circulation__game" key={item?.gameId}>
                      <div className="circulation__game-text">
                        <p className="circulation__game-title">
                          {item.hostClub} - {item.guestClub}
                        </p>
                        <p className="circulation__game-date">
                          <span>
                            {format(new Date(item.startDate), 'dd-MM-yyyy')}
                          </span>
                          <span>
                            {format(new Date(item.startDate), 'HH:mm')}
                          </span>
                        </p>
                      </div>
                      <div className="d-flex gap-2 align-items-center circulation__game-selection">
                        <span className="mobile-option-name">1</span>
                        <Checkbox
                          name="option1"
                          checked={ticketGames?.[index]?.option1 || false}
                          onChange={(e) => handleChange(e, item?.gameId)}
                        />
                        <div className="ms-2">
                          {circulationData[index]?.option1Ratio}%
                        </div>
                      </div>
                      <div className="d-flex gap-2 align-items-center circulation__game-selection">
                        <span className="mobile-option-name">x</span>
                        <Checkbox
                          name="optionX"
                          checked={ticketGames?.[index]?.optionX || false}
                          onChange={(e) => handleChange(e, item?.gameId)}
                        />
                        <div className="ms-2">
                          {circulationData[index]?.optionXRatio}%
                        </div>
                      </div>
                      <div className="d-flex gap-2 align-items-center circulation__game-selection">
                        <span className="mobile-option-name">2</span>
                        <Checkbox
                          name="option2"
                          checked={ticketGames?.[index]?.option2 || false}
                          onChange={(e) => handleChange(e, item?.gameId)}
                        />
                        <div className="ms-2">
                          {circulationData[index]?.option2Ratio}%
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <RenderIf condition={Boolean(exactTicketsCount)}>
              <div className="d-flex gap-2 my-4">
                <span className="fw-bold">Eyni bilet sayı:</span>
                <span className="text-red">{exactTicketsCount}</span>
              </div>
            </RenderIf>
            <div className="d-flex gap-2 my-4">
              <span className="fw-bold">Sütun üzrə mərc məbləği:</span>
              <span className="text-red">{columnPrice} AZN</span>
            </div>
            <div className="d-flex gap-2 my-4">
              <span className="fw-bold">Cəmi mərc məbləği:</span>
              <span className="text-red">{amount}</span>
            </div>
            <Button type="submit">Yeni bron</Button>
          </div>
        </form>
        <RenderIf condition={loading}>
          <div className="custom-progress">
            <CustomProgress />
          </div>
        </RenderIf>
      </div>
      <ToastContainer />
    </div>
  );
}

export default connect(null, playTicketsRedux.actions)(BronUpdate);
