import React from "react";

function Scan() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="14"
            fill="none"
            viewBox="0 0 15 14"
        >
            <path
                fill="#16CA68"
                d="M1.1 12.104V9.479h.876v1.75h1.75v.875H1.1zm9.917 0v-.875h1.75v-1.75h.875v2.625h-2.625zM2.705 10.5v-7H3.87v7H2.705zm1.75 0v-7h.583v7h-.583zm1.75 0v-7H7.37v7H6.205zm1.75 0v-7h1.75v7h-1.75zm2.333 0v-7h.583v7h-.583zm1.167 0v-7h.583v7h-.583zM1.1 4.52V1.897h2.625v.875h-1.75v1.75H1.1zm11.666 0V2.77h-1.75v-.874h2.625V4.52h-.875z"
            ></path>
        </svg>
    );
}

export default Scan;
