import { Fragment, useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import Button from 'components/button/Button';
import EyeInput from 'components/EyeInput';
import PhoneInput from 'components/input/PhoneInput';
import RenderIf from 'components/RenderIf';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { login } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import CustomProgress from '../components/CustomProgress';

function LogIn(props) {
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const enableLoading = () => {
    setLoading(true);
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      number: '',
      password: '',
    },
    validationSchema: Yup.object({
      number: Yup.string().required('Nömrə tələb olunur'),
      password: Yup.string().required('Şifrə tələb olunur'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      enableLoading();

      setTimeout(() => {
        const number = values.number?.replace(/[()+\s-]/g, '');
        login(number, values.password)
          .then(({ data: { token, refreshToken, expiredDate } }) => {
            disableLoading();
            formik.resetForm();
            props.login(token, refreshToken, expiredDate);
          })
          .catch((err) => {
            let message =
              err?.response?.data?.message ??
              'Düzgün istifadəçi nömrəsi və ya şifrə daxil edin.';
            setErrMsg(message);
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  const handleChange = (e) => {
    formik.setFieldValue('number', e.target.value);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Daxil ol</title>
        <meta name="description" content="1x2 - Daxil ol" />
      </Helmet>
      <div className="auth__login">
        <Link to="/" className="auth__logo">
          <img src="/media/topaz-nard-logo-black.svg" alt="Topaz" />
        </Link>
        <div className="auth__title">
          <span>Nərdə xoş gəldiniz!</span>
        </div>
        <div className="d-flex gap-2 mb-4">
          <span className="font-4 fw-500">Burada yenisiniz?</span>
          <Link to="/auth/register" className="font-4 fw-500 text-blue">
            Hesab yaradın
          </Link>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column gap-2 mb-4">
            <label className="font-4 fw-500">Telefon nömrəsi</label>
            <PhoneInput
              id="number"
              name="number"
              onChange={handleChange}
              value={formik.values.number}
            />

            {formik.touched.number && formik.errors.number ? (
              <p className="error-text">{formik.errors.number}</p>
            ) : null}
          </div>
          <div className="d-flex justify-content-between mb-2">
            <label className="font-4 fw-500">Şifrə</label>
            <Link
              to="/auth/forgot-password"
              className="font-4 fw-500 text-blue"
            >
              Şifrəni unutmusunuz?
            </Link>
          </div>
          <div className="mb-4 showButton">
            <EyeInput
              placeholder="Şifrə"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              className="w-100"
            />
            <div className="showButton-eye">
              <span
                className={`password-toggle-icon ${
                  showPassword ? 'visible' : 'hidden'
                }`}
              >
                <RenderIf condition={showPassword}>
                  <VisibilityOff
                    role="button"
                    onClick={togglePasswordVisibility}
                  />
                </RenderIf>
                <RenderIf condition={!showPassword}>
                  <Visibility
                    role="button"
                    onClick={togglePasswordVisibility}
                  />
                </RenderIf>
              </span>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <p className="error-text py-2">{formik.errors.password}</p>
            ) : null}
          </div>
          <RenderIf condition={Boolean(errMsg)}>
            <p className="error-text mb-4 text-center">{errMsg}</p>
          </RenderIf>
          <div className="">
            <Button
              disabled={formik.isSubmitting}
              type="submit"
              className="auth__submit w-100"
            >
              Daxil ol
            </Button>
          </div>
        </form>
      </div>
      {loading && (
        <div className="custom-progress">
          <CustomProgress />
        </div>
      )}
    </Fragment>
  );
}

export default connect(null, auth.actions)(LogIn);
