import React, { useEffect } from 'react';

import { Close } from 'components/icons';
import { actions } from 'modules/home/_redux/gameRedux';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { logMessageOncePerWeek } from './helper';

const useStyles = createUseStyles({
  closeButton: {
    width: '36px',
    height: '36px',
    border: 'unset',
    borderRadius: '50%',
    position: 'absolute',
    right: '10px',
    top: '10px',
    background: '#1E1E1E',
    padding: 'unset',

    '& svg': {
      stroke: '#FFFFFF',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    maxWidth: '500px',
    padding: '20px',
  },
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: '500',
    textAlign: 'center',
  },
  contents: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  contentTitle: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '16px',
    marginBottom: '4px',
  },
  contentText: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
});

const contents = [
  {
    id: 1,
    title: '💳 Balans Artırma',
    text: 'Balansınızı artıq 3 AZN-dən başlayaraq bank kartları ilə artırmaq mümkündür. Bu əməliyyat üçün istənilən bank kartlarından istifadə edə bilərsiniz. TopazNard tərəfindən əlavə komissiya haqqı tutulmur!',
  },
  {
    id: 2,
    title: '💵 Bank Kartına Köçürmə',
    text: 'Uduş məbləğinizi 5 AZN-dən başlayaraq bank kartınıza köçürə bilərsiniz. Bu əməliyyat üçün əlavə komissiya haqqı tutulmur.',
  },
  {
    id: 3,
    title: 'ℹ️ Qeyd',
    text: '⚠️ Yalnız uduş məbləğinizi çıxara bilərsiniz.',
  },
];

export default function WarningModal() {
  const { showWarningModal } = useSelector((state) => state.game);
  const dispatch = useDispatch();
  const classes = useStyles();

  function handleClose() {
    dispatch(actions.setShowWarningModal(false));
  }

  useEffect(() => {
    logMessageOncePerWeek(() => dispatch(actions.setShowWarningModal(true)));
  }, []);

  return (
    <React.Fragment>
      <Modal centered show={showWarningModal} onHide={handleClose}>
        <Modal.Body
          style={{
            padding: '10px',
          }}
        >
          <button
            type="button"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <Close />
          </button>
          <div className={classes.container}>
            <p className={classes.title}>Yeniliklərlə tanış olun!</p>
            <div className={classes.contents}>
              {contents.map((content) => (
                <div key={content.id}>
                  <p className={classes.contentTitle}>{content.title}</p>
                  <p className={classes.contentText}>{content.text}</p>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
