import React from "react";

function Download() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                fill="#00AFF5"
                d="M9.005 11.62L6.053 8.668l.729-.75 1.705 1.704V3.813h1.037v5.81l1.705-1.705.729.75-2.953 2.952zM5.07 14.187c-.35 0-.645-.121-.887-.363a1.207 1.207 0 01-.363-.887V11.06h1.037v1.876c0 .053.022.102.067.146a.203.203 0 00.146.066h7.873a.203.203 0 00.146-.066.203.203 0 00.067-.146V11.06h1.037v1.876c0 .349-.121.645-.363.887a1.207 1.207 0 01-.887.363H5.069z"
            ></path>
        </svg>
    );
}

export default Download;