const options = {
    0: "option1",
    1: "optionX",
    2: "option2"
};

const idealGameRatios = {
    8: [3, 3, 2],
    9: [4, 3, 2],
    10: [5, 3, 2],
    11: [6, 3, 2],
    12: [7, 3, 2],
    13: [8, 3, 2],
    14: [9, 3, 2],
    15: [10, 3, 2]
}

const randomIndexGenerator = (arrayLength) => Math.floor(Math.random() * arrayLength);

const findKeyByValue = (object, value) => {
    const options = [];
    for (const key in object) {
        if (object[key] === value) {
            options.push(key);
        }
    }
    return options
}

export const randomGameOptionChooser = (matches = []) => (
    matches.map(match => {
        const randomIndex = randomIndexGenerator(3);
        const newMatch = {...match};
        newMatch[options[randomIndex]] = true;
        return newMatch
    })
)

export const popularGameOptionChooser = (matches = []) => (
    matches.map(match => {
        const ratios = {
            option1: match.option1Ratio,
            optionX: match.optionXRatio,
            option2: match.option2Ratio
        }

        const popularOptionValue = Object.values(ratios).sort()[2];

        let popularOptionName;

        for (const key in ratios) {
            if (ratios[key] === popularOptionValue) {
                popularOptionName = key
                break
            }
        }
        return {
            ...match, [popularOptionName]: true
        }
    })
)

export const idealGameOptionChooser = (matches = []) => {
    if (!idealGameRatios[matches.length]) {
        return popularGameOptionChooser(matches)
    }

    const indexes = [];
    const ticketGames = structuredClone(matches);

    const generateIndex = () => {
        const randomIndex = randomIndexGenerator(matches.length);
        if (indexes.includes(randomIndex)) {
            return generateIndex()
        } else {
            indexes.push(randomIndex);
            return randomIndex
        }
    }

    idealGameRatios[matches.length].forEach((gameCount, index) => {
        [...Array(gameCount).keys()].forEach(() => {
            const randomIndex = generateIndex();

            const ratios = {
                option1: ticketGames[randomIndex].option1Ratio,
                optionX: ticketGames[randomIndex].optionXRatio,
                option2: ticketGames[randomIndex].option2Ratio,
            }
            const sortedValues = Object.values(ratios).sort();

            const choiceByIndex = {
                0: sortedValues[2],
                1: sortedValues[1],
                2: sortedValues[0]
            }

            const optionNames = findKeyByValue(ratios, choiceByIndex[index]);

            if (optionNames.length === 1) {
                ticketGames[randomIndex] = {
                    ...ticketGames[randomIndex],
                    [optionNames[0]]: true
                }
            } else if (optionNames.length === 2 && index === 0) {
                ticketGames[randomIndex] = {
                    ...ticketGames[randomIndex],
                    [optionNames[0]]: true
                }
            } else if (optionNames.length === 2 && index === 1) {
                ticketGames[randomIndex] = {
                    ...ticketGames[randomIndex],
                    [optionNames[1]]: true
                }
            } else if (optionNames.length === 2 && index === 2) {
                ticketGames[randomIndex] = {
                    ...ticketGames[randomIndex],
                    [optionNames[1]]: true
                }
            } else if (optionNames.length === 3 && index === 0) {
                ticketGames[randomIndex] = {
                    ...ticketGames[randomIndex],
                    [optionNames[0]]: true
                }
            } else if (optionNames.length === 3 && index === 1) {
                ticketGames[randomIndex] = {
                    ...ticketGames[randomIndex],
                    [optionNames[1]]: true
                }
            } else if (optionNames.length === 3 && index === 2) {
                ticketGames[randomIndex] = {
                    ...ticketGames[randomIndex],
                    [optionNames[2]]: true
                }
            }


        })
    })
    return ticketGames

}

export const checkCirculationIsNotPlayed = (matches = []) => matches.every(match => match.option1Ratio === 0 && match.optionXRatio === 0 && match.option2Ratio === 0);
export const ticketPriceCalculator = (matches, columnPrice) => {
    return columnPrice * matches.reduce((acc, match) => {
        let checkCount = 0
        for (const field in match) {
            match[field] === true && checkCount++
        }

        return checkCount ? acc * checkCount : acc
    }, 1).toFixed(2)
}

export const predictionsMonitor = (matches) => {
    if(matches.length === 0) return false
    return matches.every(match => {
        for (const options in match) {
            if (match[options] === true) {
                return true
            }
        }
        return false
    });
}

export const findTicketBetType = (matches) => {
    const isMulti = matches.some(match => Object.values(match.options).reduce((acc, value) => value ? acc + 1 : acc, 0) > 1);
    return isMulti ? "Çoxvariantlı" : "Təkvariantlı"
}