import React from "react";

const Email = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M1.72094 6.8429C1.51915 6.70046 1.41825 6.62924 1.33222 6.61186C1.14267 6.57354 0.946413 6.67523 0.868405 6.85219C0.833003 6.9325 0.833004 7.05464 0.833008 7.29891V12.7011C0.832998 13.3719 0.832989 13.9255 0.869837 14.3765C0.889604 14.6185 1.17167 14.7158 1.35157 14.5529L5.68007 10.6318C5.89094 10.4407 5.99638 10.3452 6.03 10.2355C6.05948 10.1394 6.05318 10.0358 6.01228 9.94394C5.96563 9.83916 5.8494 9.75711 5.61694 9.59302L1.72094 6.8429Z"/>
        <path d="M2.2423 15.9948C2.07261 16.1485 1.98776 16.2254 1.95418 16.3353C1.92814 16.4204 1.93594 16.546 1.97232 16.6273C2.01924 16.7321 2.09663 16.7864 2.2514 16.8951C2.3789 16.9845 2.51305 17.0654 2.65304 17.1367C3.05749 17.3428 3.48807 17.4249 3.95649 17.4632C4.40748 17.5 4.96111 17.5 5.63191 17.5H14.3674C15.0382 17.5 15.5919 17.5 16.0429 17.4632C16.5113 17.4249 16.9419 17.3428 17.3463 17.1367C17.4863 17.0654 17.6205 16.9845 17.748 16.8951C17.9027 16.7864 17.9801 16.7321 18.027 16.6272C18.0634 16.5459 18.0712 16.4204 18.0452 16.3353C18.0116 16.2254 17.9267 16.1485 17.7571 15.9948L12.6573 11.375C12.5599 11.2867 12.5111 11.2426 12.4584 11.2167C12.3585 11.1678 12.2431 11.1608 12.1381 11.1973C12.0826 11.2166 12.0289 11.2545 11.9215 11.3304C11.4654 11.6533 11.065 11.9369 10.6082 12.0515C10.2088 12.1517 9.79072 12.1517 9.39125 12.0515C8.93452 11.9369 8.5341 11.6533 8.07801 11.3304C7.97055 11.2545 7.91682 11.2165 7.86129 11.1972C7.75624 11.1607 7.64096 11.1677 7.54109 11.2167C7.4883 11.2425 7.43955 11.2867 7.34205 11.375L2.2423 15.9948Z"/>
        <path d="M18.6478 14.5529C18.8277 14.7158 19.1097 14.6185 19.1295 14.3765C19.1664 13.9255 19.1664 13.3719 19.1663 12.7011V7.2989C19.1663 7.05472 19.1663 6.93263 19.131 6.85235C19.053 6.67533 18.8567 6.57361 18.6671 6.61196C18.5811 6.62936 18.4802 6.70055 18.2785 6.84294L14.3825 9.59307C14.15 9.75716 14.0338 9.8392 13.9871 9.94399C13.9462 10.0359 13.9399 10.1394 13.9694 10.2356C14.003 10.3453 14.1085 10.4408 14.3193 10.6318L18.6478 14.5529Z"/>
        <path d="M18.566 3.92274C18.657 4.05265 18.7025 4.1176 18.7165 4.2164C18.7274 4.2934 18.7087 4.40075 18.6724 4.46951C18.6258 4.55774 18.547 4.61337 18.3894 4.72461L11.057 9.90041C10.4464 10.3314 10.3169 10.4063 10.2026 10.4349C10.0694 10.4684 9.93007 10.4684 9.79691 10.4349C9.68259 10.4063 9.55307 10.3314 8.94249 9.90041L1.61002 4.72454C1.45242 4.6133 1.37362 4.55767 1.32701 4.46944C1.29069 4.40068 1.27197 4.29332 1.28289 4.21633C1.2969 4.11753 1.34239 4.05257 1.43337 3.92266C1.74527 3.47733 2.16967 3.1096 2.65304 2.86331C3.05749 2.65724 3.48807 2.5751 3.95649 2.53683C4.40748 2.49998 4.96111 2.49999 5.63191 2.5H14.3674C15.0382 2.49999 15.5919 2.49998 16.0429 2.53683C16.5113 2.5751 16.9419 2.65724 17.3463 2.86331C17.8297 3.10962 18.2541 3.47737 18.566 3.92274Z"/>
    </svg>
)

export default Email