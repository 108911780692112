import { Error } from 'components/icons';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

const useStyles = createUseStyles({
  container: {
    background: '#FFFFFF',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  box: {
    maxWidth: '450px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
  },
  text: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: '500',
    textAlign: 'center',
  },
  button: {
    background: '#162c35',
    borderRadius: '30px',
    padding: '10px',
    color: '#FFFFFF',
    width: '100%',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
  },
});

export default function PaymentError() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div className={classes.box}>
        <Error />
        <span className={classes.text}>Əməliyyat uğursuzdur</span>
        <button onClick={() => navigate('/')} className={classes.button}>
          Əsas səhifəyə keç
        </button>
      </div>
    </div>
  );
}
