import React from "react";

function Calendar(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
            {...props}
        >
            <path
                fill="#A8A8A8"
                d="M3.538 14.333c-.337 0-.622-.116-.855-.35a1.163 1.163 0 01-.35-.855V4.205c0-.337.117-.622.35-.855.233-.233.518-.35.855-.35h.923V1.59h1.026V3h5.051V1.59h1V3h.923c.337 0 .622.117.855.35.234.233.35.518.35.855v8.923c0 .337-.116.622-.35.855-.233.234-.518.35-.855.35H3.538zm0-1h8.923a.196.196 0 00.141-.064.196.196 0 00.064-.14V6.871H3.333v6.256c0 .052.021.098.064.141.043.043.09.064.141.064zm-.205-7.461h9.333V4.205a.196.196 0 00-.064-.14.196.196 0 00-.14-.065H3.537a.196.196 0 00-.14.064.196.196 0 00-.065.141v1.667zM8 9.385a.568.568 0 01-.418-.173.568.568 0 01-.172-.417c0-.163.057-.302.172-.417A.569.569 0 018 8.205c.163 0 .302.058.417.173a.569.569 0 01.172.417.568.568 0 01-.172.417.568.568 0 01-.417.173zm-2.667 0a.569.569 0 01-.417-.173.568.568 0 01-.173-.417c0-.163.058-.302.173-.417a.569.569 0 01.417-.173c.163 0 .302.058.417.173a.569.569 0 01.173.417.568.568 0 01-.173.417.568.568 0 01-.417.173zm5.333 0a.568.568 0 01-.417-.173.568.568 0 01-.172-.417c0-.163.057-.302.172-.417a.569.569 0 01.417-.173c.164 0 .303.058.418.173a.569.569 0 01.172.417.568.568 0 01-.172.417.569.569 0 01-.418.173zM8 12a.568.568 0 01-.418-.172.568.568 0 01-.172-.418c0-.163.057-.302.172-.417A.568.568 0 018 10.82c.163 0 .302.058.417.173a.568.568 0 01.172.417.568.568 0 01-.172.418A.568.568 0 018 12zm-2.667 0a.569.569 0 01-.417-.172.568.568 0 01-.173-.418c0-.163.058-.302.173-.417a.569.569 0 01.417-.173c.163 0 .302.058.417.173a.568.568 0 01.173.417.568.568 0 01-.173.418.568.568 0 01-.417.172zm5.333 0a.568.568 0 01-.417-.172.568.568 0 01-.172-.418c0-.163.057-.302.172-.417a.568.568 0 01.417-.173c.164 0 .303.058.418.173a.568.568 0 01.172.417.568.568 0 01-.172.418.569.569 0 01-.418.172z"
            ></path>
        </svg>
    );
}

export default Calendar;
