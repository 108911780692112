import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';
import {HashRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';

import SignalRProvider from "./providers/SignalRProvider";
import {Router} from './routes/Router';
import 'bootstrap/dist/css/bootstrap.min.css';

function App({store, persistor}) {
    return (
        <HelmetProvider>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <HashRouter>
                        <SignalRProvider>
                            <Router/>
                        </SignalRProvider>
                    </HashRouter>
                </PersistGate>
            </Provider>
        </HelmetProvider>
    );
}

export default App;
