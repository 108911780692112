import HomeLayout from 'components/layout/HomeLayout';

import Partners from '../modules/partners';

const PartnersPage = () => (
    <HomeLayout>
        <Partners />
    </HomeLayout>
);

export default PartnersPage;