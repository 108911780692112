import React from "react";

import {Helmet} from "react-helmet-async";

import "./Partners.css";

const Partners = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Partnyorlar | Nard</title>
                <meta name="description" content="Partnyorlar | Nard"/>
            </Helmet>
           <div className="partners">
               <div className="partners__container px-2 px-sm-4">
                   <h1 className="partners__title">Partnyorlar</h1>
                   <div className="partners__list">
                       <a href="https://crocusoft.az" rel="noreferrer" target="_blank" className="partners__item">
                           <img src="/media/crocusoft.png" alt="Crocusoft"/>
                           <div className="partners__caption">
                               <p className="partners__item__title">Crocusoft</p>
                               <p className="partners__description">
                                   Crocusoft 2015-ci ildən yerli və xarici bazarlar üçün proqram təminatı həlləri hazırlanması
                                   üzrə ixtisaslaşmış, gənc, innovativ komandaya sahib proqram təminatı hazırlanması
                                   şirkətidir.
                               </p>
                           </div>
                       </a>
                   </div>
               </div>
           </div>
        </React.Fragment>
    )
}

export default Partners