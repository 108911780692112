import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { Balance, Close, Money } from 'components/icons';
import { useFormik } from 'formik';
import { actions } from 'modules/auth/_redux/authRedux';
import { actions as scanActions } from 'modules/home/_redux/scanRedux';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

const useStyles = createUseStyles({
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: 500,
  },
  closeButton: {
    width: '36px',
    height: '36px',
    border: 'unset',
    borderRadius: '50%',
    position: 'absolute',
    right: '30px',
    top: '20px',
    background: '#1E1E1E',
    padding: 'unset',

    '& svg': {
      stroke: '#FFFFFF',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
  },
  text: {
    maxWidth: '474px',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  inputContainer: {
    padding: '12px 16px',
    borderRadius: '10px',
    background: '#F5F5F5',
    '& svg': {
      marginRight: '8px',
    },
  },
  input: {
    border: 'unset',
    background: '#f5f5f5',
    outline: 'unset',
    borderRadius: '8px',
    padding: '16px',
    width: '100%',
  },
  select: {
    border: 'unset',
    background: '#f5f5f5',
    outline: 'unset',
    borderRadius: '8px',
    padding: '16px',
    width: '100%',
  },
  form: {
    display: 'flex',
    gap: '12px',
    flexWrap: 'wrap',
    width: '100%',
  },
  formText: {
    color: '#000000',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  submit: {
    backgroundColor: '#162C35',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    textAlign: 'center',
    padding: '10px',
    borderRadius: '30px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    color: '#FFFFFF !important',
    height: '24px !important',
    width: '24px !important',
  },
  dialog: {
    maxWidth: '450px',
  },
  cards: {
    display: 'flex',
    gap: '8px',
  },
  card: {
    background: 'rgba(245, 245, 245, 0.7)',
    borderRadius: '8px',
    padding: '8px 14px 8px 14px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    width: '100%',
    alignItems: 'center',
  },
  cardText: {
    display: 'flex',
    flexDirection: 'column',
    '& span:first-child': {
      color: '#999999',
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    '& span:last-child': {
      color: '#011316',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
    },
  },
});

export default function WithdrawByCreditCardModal({ show, onHide }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { playerBalance, winningAmount } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      pan: '',
    },
    validationSchema: Yup.object({
      pan: Yup.string()
        .matches(/^\d{16}$/, 'Kart nömrəsini düzgün daxil edin.')
        .required('Kart nömrəsi tələb olunur.'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const { data } = await axios.post('winning/create', {});
        await axios.post('payment/cib-withdraw', {
          pan: values.pan,
          amount: data?.amount,
          winningVoucherCode: data?.voucherNumber,
        });
        toast.success('Məbləğ kartınıza göndərildi!');
        onHide(false);
      } catch (err) {
        toast.error(err.response?.data?.message ?? 'Xəta baş verdi!');
      } finally {
        dispatch(actions.getProfile());
        dispatch(scanActions.setRandomId(uuid()));
        setSubmitting(false);
      }
    },
  });

  function handleClose() {
    onHide(false);
  }

  useEffect(() => {
    dispatch(scanActions.setScannerDisabled(true));
    dispatch(actions.getWinningAmount());
    return () => {
      dispatch(scanActions.setScannerDisabled(false));
    };
  }, []);

  return (
    <React.Fragment>
      <Modal
        centered
        show={show}
        onHide={onHide}
        dialogClassName={classes.dialog}
      >
        <Modal.Body style={{ padding: '32px' }}>
          <button
            type="button"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <Close />
          </button>
          <div className={classes.container}>
            <p className={classes.title}>Karta köçürmək</p>
            <div className={classes.cards}>
              <div className={classes.card}>
                <Balance />
                <div className={classes.cardText}>
                  <span>Balansda olan məbləğ</span>
                  <span>{playerBalance} &#8380;</span>
                </div>
              </div>
              <div className={classes.card}>
                <Money />
                <div className={classes.cardText}>
                  <span>Çıxarıla bilən məbləğ</span>
                  <span>{winningAmount} &#8380;</span>
                </div>
              </div>
            </div>
            <form className={classes.form} onSubmit={formik.handleSubmit}>
              <div className="w-100 d-flex flex-column gap-2">
                <input
                  type="text"
                  className={classes.input}
                  placeholder="Kartın 16 rəqəmi*"
                  inputMode="numeric"
                  {...formik.getFieldProps('pan')}
                />
              </div>
              {formik.touched.pan && formik.errors.pan && (
                <span className="text-red">{formik.errors.pan}</span>
              )}
              <p className={classes.formText}>
                Bank kartı vasitəsilə minimum pul çıxarışı 5 AZN təşkil edir.
                Açılan səhifədə kartınızın 16 rəqəmini qeyd edin
              </p>
              <button
                className={classes.submit}
                type="submit"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? (
                  <CircularProgress className={classes.progress} />
                ) : (
                  'Karta köçür'
                )}
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
