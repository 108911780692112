import React, { useRef } from 'react';

import { Close } from 'components/icons';
import format from 'date-fns/format';
import { az } from 'date-fns/locale';
import Barcode from 'react-barcode';
import { Modal } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa6';
import { createUseStyles } from 'react-jss';
import { useReactToPrint } from 'react-to-print';

const useStyles = createUseStyles({
  title: {
    fontSize: '32px',
    textAlign: 'center',
    lineHeight: '42px',
    fontWeight: 500,
  },
  closeButton: {
    width: '36px',
    height: '36px',
    border: 'unset',
    borderRadius: '50%',
    position: 'absolute',
    right: '30px',
    top: '20px',
    background: '#1E1E1E',
    padding: 'unset',

    '& svg': {
      stroke: '#FFFFFF',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    marginBottom: '34px',
  },
  text: {
    maxWidth: '474px',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  inputContainer: {
    padding: '12px 16px',
    borderRadius: '10px',
    background: '#F5F5F5',
    '& svg': {
      marginRight: '8px',
    },
  },
  input: {
    border: 'unset',
    background: 'unset',
    outline: 'unset',
  },
  form: {
    display: 'flex',
    gap: '12px',
  },
  button: {
    background: 'unset',
    '& svg': {
      width: '25px',
      height: '25px',
    },
  },
  modalBody: {
    padding: '60px 50px !important',
  },
  line: {
    width: '100%',
    borderBottom: '2px dashed black',
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    paddingInline: '12px',
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '12px',
    fontWeight: '500',
    // lineHeight: '24px',
  },
  boldText: {
    fontSize: '16px',
    fontWeight: '600',
  },
  logo: {
    paddingTop: '24px',
  },
});

export default function PrintModal({ show, onHide, printData }) {
  const classes = useStyles();
  const ref = useRef();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    // onAfterPrint: () => onHide(false),
  });

  function handleClose() {
    onHide(false);
  }

  return (
    <React.Fragment>
      <Modal centered show={show} onHide={onHide}>
        <Modal.Body className={classes.modalBody}>
          <button
            type="button"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <Close />
          </button>
          <div ref={ref} className={classes.container}>
            <img
              className={classes.logo}
              src="/media/topaz-nard-logo-black.svg"
              alt="topaz-nard"
            />
            <Barcode value={printData?.voucherNumber} />
            <div className={classes.line}></div>
            <div className={classes.footer}>
              <div className={classes.row}>
                <span>Yaradılma tarixi</span>
                <span>
                  {printData?.createdDateTime &&
                    format(
                      new Date(printData.createdDateTime),
                      'dd MMMM yyyy  HH:mm:ss',
                      {
                        locale: az,
                      }
                    )}
                </span>
              </div>
              <div className={classes.row}>
                <span>Oyunçu</span>
                <span>{'********' + printData?.customerNumber?.slice(-4)}</span>
              </div>
              <div className={classes.row}>
                <span className={classes.boldText}>Məbləğ</span>
                <span className={classes.boldText}>
                  {printData?.amount} AZN
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            Çap et
            <button onClick={handlePrint} className={classes.button}>
              <FaPrint />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
