import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { initMessageListener } from 'redux-state-sync';

import { rootReducer, rootSaga } from './rootReducer';

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  sagaMiddleware,
  createSagaMiddleware({
    blackList: ['persist/PERSIST', 'persist/REHYDRATE'],
  }),
];

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

initMessageListener(store);

export const persist = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
