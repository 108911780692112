import React, { useEffect } from 'react';

import { Card, Close, Ticket } from 'components/icons';

import { actions } from 'modules/auth/_redux/authRedux';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const useStyles = createUseStyles({
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: 500,
  },
  closeButton: {
    width: '36px',
    height: '36px',
    border: 'unset',
    borderRadius: '50%',
    position: 'absolute',
    right: '10px',
    top: '10px',
    background: '#1E1E1E',
    padding: 'unset',

    '& svg': {
      stroke: '#FFFFFF',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  text: {
    maxWidth: '474px',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  inputContainer: {
    padding: '12px 16px',
    borderRadius: '10px',
    background: '#F5F5F5',
    '& svg': {
      marginRight: '8px',
    },
  },
  input: {
    border: 'unset',
    background: 'unset',
    outline: 'unset',
  },
  button: {
    background: '#f5f5f5',
    width: '100%',
    borderRadius: '18px',
    color: '#000000',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  methods: {
    display: 'flex',
    gap: '20px',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
  method: {
    background: 'rgba(245, 245, 245, 0.64)',
    borderRadius: '18px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    flexGrow: 1,
    border: '1px solid transparent',
    '@media (max-width: 768px)': {
      padding: '12px 16px',
    },
    transition: 'all ease .3s',
    '&:hover': {
      borderColor: '#2098e1',
    },
  },
  dialog: {
    maxWidth: '450px',
  },
});

export default function WithdrawModal({
  show,
  onHide,
  setShowWithdrawByVoucherModal,
  setShowWithdrawByCreditCardModal,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { winningAmount, id } = useSelector((state) => state.auth);

  function handleClose() {
    onHide(false);
  }

  function handleClickWithdrayByCreditCard() {
    if (winningAmount < 5) {
      return toast.error(
        'Bank kartı vasitəsilə minimum pul çıxarışı 5 AZN təşkil edir.'
      );
    }
    setShowWithdrawByCreditCardModal(true);
    handleClose();
  }

  const methods = [
    {
      id: 1,
      title: 'Bank kartı ilə',
      icon: <Card />,
      onClick: handleClickWithdrayByCreditCard,
    },
    {
      id: 2,
      title: 'Vauçer kod ilə',
      icon: <Ticket />,
      onClick: () => {
        setShowWithdrawByVoucherModal(true);
        handleClose();
      },
    },
  ];

  useEffect(() => {
    dispatch(actions.getWinningAmount());
  }, []);

  return (
    <React.Fragment>
      <Modal
        centered
        show={show}
        onHide={onHide}
        dialogClassName={classes.dialog}
      >
        <Modal.Body
          style={{
            padding: '22px 32px',
          }}
        >
          <button
            type="button"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <Close />
          </button>
          <div className={classes.container}>
            <p className={classes.title}>Pulu çıxartmaq</p>
            <div className={classes.methods}>
              {methods.map((method) => (
                <button
                  key={method.id}
                  onClick={method.onClick}
                  className={`${classes.method}`}
                >
                  {method.icon}
                  <span className={`${classes.methodText}`}>
                    {method.title}
                  </span>
                </button>
              ))}
            </div>
          </div>
          {/* <div className={classes.buttons}>
            <Button
              disabled={formik.isSubmitting}
              className={classes.button}
              onClick={formik.handleSubmit}
            >
              Uduş vauçeri çıxartmaq
              <FaArrowRight />
            </Button>
            <Button
              disabled={formik.isSubmitting}
              className={classes.button}
              onClick={handleClickWithdrayByCreditCard}
            >
              Uduş məbləğini karta köçürmək
              <FaArrowRight />
            </Button>
          </div> */}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
