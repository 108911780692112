import React from "react";

const LegalAge = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
        <path d="M21.2568 13.8361C20.9097 13.5719 20.4932 13.4431 20.0004 13.4431C19.4659 13.4431 19.0009 13.5922 18.6191 13.8836C18.2373 14.1817 18.043 14.5883 18.043 15.1101C18.043 15.5573 18.2096 15.9097 18.5358 16.1672C18.869 16.4315 19.2785 16.5602 19.7713 16.5602C20.3197 16.5602 20.7917 16.4112 21.1874 16.1198C21.5761 15.8216 21.7774 15.4218 21.7774 14.9068C21.7704 14.4596 21.6038 14.1004 21.2568 13.8361Z" fill="#FF0009"/>
        <path d="M20.0082 19.5687C19.6473 19.2977 19.203 19.1689 18.6755 19.1689C18.0994 19.1689 17.6135 19.3248 17.2248 19.6297C16.8361 19.9414 16.6348 20.3616 16.6348 20.8969C16.6348 21.3645 16.8083 21.7372 17.1484 22.015C17.4955 22.2928 17.9258 22.4284 18.4464 22.4284C19.0226 22.4284 19.5154 22.2725 19.9319 21.9676C20.3414 21.6559 20.5496 21.2357 20.5496 20.7004C20.5566 20.2193 20.3761 19.8398 20.0082 19.5687Z" fill="#FF0009"/>
        <path d="M32.0852 19.955L31.2592 23.4583H28.5591L29.3851 19.955H25.8797L26.4975 17.3122H30.0237L30.8497 13.8088H33.5499L32.7238 17.3122H35.8891C36.4721 12.0267 34.7576 6.88341 30.7247 3.50879C23.6863 -2.38663 12.2887 -0.678992 5.2641 7.32387C-1.76048 15.3267 -1.75354 26.5958 5.27798 32.4912C12.3165 38.3866 23.714 36.679 30.7456 28.6761C33.0778 26.0198 34.6258 23.0111 35.4101 19.955H32.0852ZM6.98554 24.7526L9.23452 15.259L5.62505 16.8717L6.43024 13.5107L11.3169 11.2542H13.4618L10.2827 24.7526H6.98554ZM24.0195 20.8833C24.0195 22.0895 23.485 23.0924 22.423 23.8785C21.354 24.6645 19.9796 25.0576 18.286 25.0576C16.7172 25.0576 15.4609 24.7052 14.5099 24.0072C13.5589 23.3093 13.0869 22.4012 13.0869 21.2899C13.0869 20.4158 13.3785 19.6636 13.9615 19.0266C14.5446 18.3896 15.3637 17.9356 16.4049 17.6646C15.3984 17.0615 14.8986 16.1534 14.8986 14.9473C14.8986 13.7953 15.3984 12.8398 16.4049 12.0876C17.4114 11.3355 18.6747 10.956 20.2018 10.956C21.6455 10.956 22.8047 11.3016 23.6863 11.9928C24.5678 12.684 24.9843 13.5717 24.9427 14.6627C24.8732 16.3296 23.95 17.4545 22.18 18.0508C23.4086 18.6607 24.0195 19.6026 24.0195 20.8833Z" fill="#FF0009"/>
    </svg>
)

export default LegalAge