import axios from "axios";

const GET_TOURNAMENTS_URL = 'https://api.nards.club/api/nards/site/tournaments';


const getTournaments = (limit = 10) => (
    axios.get(GET_TOURNAMENTS_URL, {
        params: {
            offset: 0,
            limit
        }
    })
)

export {getTournaments}