const Money = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#11DF88"
      d="M19.17 6.64c-.43-2.17-2.04-3.12-4.28-3.12H6.11c-2.64 0-4.4 1.32-4.4 4.4v5.15c0 2.22.91 3.52 2.41 4.08.22.08.46.15.71.19.4.09.83.13 1.28.13h8.79c2.64 0 4.4-1.32 4.4-4.4V7.92c0-.47-.04-.89-.13-1.28M5.53 12c0 .41-.34.75-.75.75s-.75-.34-.75-.75V9c0-.41.34-.75.75-.75s.75.34.75.75zm4.97 1.14c-1.46 0-2.64-1.18-2.64-2.64s1.18-2.64 2.64-2.64 2.64 1.18 2.64 2.64-1.18 2.64-2.64 2.64M16.96 12c0 .41-.34.75-.75.75s-.75-.34-.75-.75V9c0-.41.34-.75.75-.75s.75.34.75.75z"
    ></path>
    <path
      fill="#11DF88"
      d="M22.3 10.92v5.15c0 3.08-1.76 4.41-4.41 4.41H9.11c-.75 0-1.42-.11-2-.33-.47-.17-.88-.42-1.21-.74-.18-.17-.04-.44.21-.44h8.78c3.7 0 5.9-2.2 5.9-5.89V7.92c0-.24.27-.39.44-.21.68.72 1.07 1.77 1.07 3.21"
    ></path>
  </svg>
);

export default Money;
