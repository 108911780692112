import { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import RenderIf from 'components/RenderIf';
import { format } from 'date-fns';
import CustomProgress from 'modules/auth/components/CustomProgress';
import Barcode from 'react-barcode';
import { Button, Modal } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';

import { findTicketBetType } from '../helpers';

function BarcodeModal({ show, onHide, url, ticketInformation, enablePrint }) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [betType, setBetType] = useState('');

  const { ticketId, barcode, price } = ticketInformation;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getTicketGames = () => {
    axios
      .get(`${url}/byId?id=${ticketId}`)
      .then(({ data }) => {
        setData(data);
        const betType = findTicketBetType(data.ticketGames);
        setBetType(betType);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getTicketGames();
  }, [ticketId]);

  return (
    <Modal centered size="md" show={show} onHide={() => onHide(false)}>
      <Modal.Header closeButton>
        <h1>Kupon məlumatları:</h1>
      </Modal.Header>
      <Modal.Body ref={componentRef}>
        {isLoading ? (
          <CustomProgress />
        ) : (
          <>
            <div className="col-12 pl-6 pr-6">
              <div className="d-flex justify-content-center">
                <img
                  src="/media/1x2-logo.svg"
                  alt="1x2"
                  style={{ width: '100px' }}
                />
              </div>
              <div className="divider"></div>
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 ticketgame">
                  <div className="w-100 pt-2 pb-2 pt-2 d-flex justify-content-between flex-wrap ticket-text__container">
                    <p className="font-weight-bold ticket-text">
                      {url === 'voidTickets' ? 'Bron' : 'Bilet'} ID:
                    </p>
                    <p className="ticket-text">{data.id}</p>
                  </div>
                    <div className="w-100 pt-2 pb-2 pt-2 d-flex justify-content-between flex-wrap ticket-text__container">
                        <p className="font-weight-bold ticket-text">Məhsul:</p>
                        <p className="ticket-text">{data.productName}</p>
                    </div>
                  <div className="w-100 pt-2 pb-2 pt-2 d-flex justify-content-between flex-wrap ticket-text__container">
                    <p className="font-weight-bold ticket-text">Tur nömrəsi:</p>
                    <p className="ticket-text">{data.circulationNumber}</p>
                  </div>
                  <div className="w-100 pt-2 pb-2 pt-2 d-flex justify-content-between flex-wrap ticket-text__container">
                    <p className="font-weight-bold ticket-text">Oyunçu ID:</p>
                    <p className="ticket-text">
                      {data.customerNumber &&
                        `${data.customerNumber.substring(0, 7)}*****`}
                    </p>
                  </div>
                  <div className="w-100 pt-2 pb-2 pt-2 d-flex justify-content-between flex-wrap ticket-text__container">
                    <p className="font-weight-bold ticket-text">
                      Yaradılma tarixi:
                    </p>
                    <p className="ticket-text">
                      {data?.createdDate &&
                        format(
                          new Date(data?.createdDate),
                          'dd.MM.yyyy HH:mm:ss'
                        )}
                    </p>
                  </div>
                  <div className="w-100 pt-2 pb-2 pt-2 d-flex justify-content-between flex-wrap ticket-text__container print-none">
                    <p className="font-weight-bold ticket-text">Oyunlar:</p>
                    <p className="ticket-text">Seçimlər:</p>
                  </div>
                </div>
                <div className="print-none">
                  {data?.ticketGames?.map((ticket, index) => (
                    <div
                      key={index}
                      className="col-12 col-md-12 col-lg-12 ticketgame"
                    >
                      <div className="w-100 pt-2 pb-2 pt-2 d-flex justify-content-between flex-wrap ticket-text__container">
                        <span className="ticket-text">
                          {index + 1}. {ticket.hostClub} - {ticket.guestClub}
                        </span>
                        <span className="prediction ticket-text">
                          {ticket.options.option1 &&
                          ticket.options.optionX &&
                          ticket.options.option2
                            ? '1/x/2'
                            : ticket.options.option1 && ticket.options.optionX
                            ? '1/x'
                            : ticket.options.option1 && ticket.options.option2
                            ? '1/2'
                            : ticket.options.optionX && ticket.options.option2
                            ? 'x/2'
                            : ticket.options.option1
                            ? '1'
                            : ticket.options.option2
                            ? '2'
                            : ticket.options.optionX
                            ? 'x'
                            : ''}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="divider"></div>
                <div className="w-100 d-flex justify-content-between flex-wrap">
                  <p className="font-weight-bold ticket-text">Mərc tipi:</p>
                  <p className="ticket-text">{betType}</p>
                </div>
                  <div className="divider"></div>
                  <div className="w-100 d-flex justify-content-between flex-wrap">
                      <p className="font-weight-bold ticket-text">Misli:</p>
                      <p className="ticket-text">{data?.times}</p>
                  </div>
                <div className="divider"></div>
                <div className="w-100 d-flex justify-content-between flex-wrap">
                  <p className="font-weight-bold ticket-text">
                    Yekun mərc məbləği:
                  </p>
                  <p className="ticket-text">{`${price} AZN`}</p>
                </div>
                <div className="divider"></div>
              </div>
            </div>
            <RenderIf condition={enablePrint}>
              <div className="d-flex justify-content-center">
                <Barcode value={barcode} />
              </div>
            </RenderIf>
            <RenderIf condition={url === 'voidTickets'}>
              <div className="d-flex justify-content-center">
                <div className="void-ticket__container">
                  <span>BRON ET KUPONU</span>
                </div>
              </div>
            </RenderIf>
          </>
        )}
      </Modal.Body>
      <RenderIf condition={enablePrint}>
        <Modal.Footer>
          <Button
            onClick={() => {
              handlePrint();
              onHide(false);
            }}
          >
            Çap et
          </Button>
        </Modal.Footer>
      </RenderIf>
    </Modal>
  );
}

export default BarcodeModal;
