import HomeLayout from 'components/layout/HomeLayout';
import PaymentSuccess from 'modules/payment/PaymentSuccess';

const PaymentSuccessPage = () => (
  <HomeLayout>
    <PaymentSuccess />
  </HomeLayout>
);

export default PaymentSuccessPage;
