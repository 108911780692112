function Dots() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#6F6B6C"
        d="M3.5 8.167c-.321 0-.596-.115-.824-.343A1.123 1.123 0 012.333 7c0-.32.114-.595.343-.824.228-.228.503-.343.824-.343.32 0 .595.115.824.343.228.229.342.503.342.824 0 .32-.114.596-.342.824a1.123 1.123 0 01-.824.343zm3.5 0c-.321 0-.596-.115-.824-.343A1.123 1.123 0 015.833 7c0-.32.114-.595.343-.824.228-.228.503-.343.824-.343.32 0 .595.115.824.343.228.229.342.503.342.824 0 .32-.114.596-.342.824A1.123 1.123 0 017 8.167zm3.5 0c-.321 0-.596-.115-.824-.343A1.123 1.123 0 019.333 7c0-.32.114-.595.343-.824.228-.228.503-.343.824-.343.32 0 .595.115.824.343.228.229.342.503.342.824 0 .32-.114.596-.342.824a1.123 1.123 0 01-.824.343z"
      ></path>
    </svg>
  );
}

export default Dots;
