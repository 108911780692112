import React, { useState } from 'react';

import RenderIf from 'components/RenderIf';
import CustomProgress from 'modules/auth/components/CustomProgress';
import { Helmet } from 'react-helmet-async';

import Table from '../home/components/Table';
import Barcode from '../home/modals/Barcode';

import './MyTickets.css';

export default function MyTickets() {
  const [isLoading, setIsLoading] = useState(true);
  const [showBarcode, setShowBarcode] = useState(false);
  const [ticketInformation, setTicketInformation] = useState({});

  return (
    <React.Fragment>
      <Helmet>
        <title>Biletlərim | 1x2</title>
        <meta name="description" content="Ana səhifə | 1x2" />
      </Helmet>
      <div className="my-tickets">
        <div className="container my-tickets__container">
          <div className="my-tickets__title">
            <span>Biletlərim</span>
          </div>

          <div className="wrapper-table">
            <Table
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setShowBarcode={setShowBarcode}
              setTicketInformation={setTicketInformation}
            />
          </div>
        </div>
      </div>
      <RenderIf condition={isLoading}>
        <CustomProgress />
      </RenderIf>
      <RenderIf condition={showBarcode}>
        <Barcode
          show={showBarcode}
          onHide={setShowBarcode}
          ticketInformation={ticketInformation}
          url="tickets"
        />
      </RenderIf>
    </React.Fragment>
  );
}
