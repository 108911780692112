function Line() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1"
      height="24"
      fill="none"
      viewBox="0 0 1 24"
    >
      <path stroke="#343434" strokeWidth="0.5" d="M0.25 0L0.25 24"></path>
    </svg>
  );
}

export default Line;
