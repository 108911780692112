import { useEffect } from 'react';

import RenderIf from 'components/RenderIf';
import { actions } from 'modules/auth/_redux/authRedux';
import ResultPage from 'pages/ResultPage';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import WarningModal from 'components/warningModal';
import PaymentErrorPage from 'pages/PaymentErrorPage';
import PaymentSuccessPage from 'pages/PaymentSuccessPage';
import { useAuth } from '../hooks';
import UpdateBirthDate from '../modules/auth/components/UpdateBirthDate';
import Logout from '../modules/auth/pages/Logout';
import AuthPage from '../pages/AuthPage';
import BronPage from '../pages/BronPage';
import DocumentsPage from '../pages/DocumentsPage';
import HomePage from '../pages/HomePage';
import LivePage from '../pages/LivePage';
import PartnersPage from '../pages/PartnersPage';
import SportsmenPage from '../pages/SportsmenPage';
import PrivateRoutes from './PrivateRoutes';

export function Router() {
  const isAuth = useAuth();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth);

  const location = useLocation();

  useEffect(() => {
    if (isAuth) {
      dispatch(actions.getProfile());
      dispatch(actions.getWinningAmount());
    }
  }, [dispatch, isAuth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/results" element={<ResultPage />} />
        <Route path="/sportsmen" element={<SportsmenPage />} />
        <Route path="/partners" element={<PartnersPage />} />
        <Route path="/documents" element={<DocumentsPage />} />
        <Route path="/bron" element={<BronPage />} />
        <Route path="/payment-success" element={<PaymentSuccessPage />} />
        <Route path="/payment-error" element={<PaymentErrorPage />} />

        <Route path="/logout/*" element={<Logout />} />
        {isAuth ? (
          <Route path="/*" element={<PrivateRoutes />} />
        ) : (
          <Route path="/auth/*" element={<AuthPage />} />
        )}
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/login" element={<Navigate to="/" replace />} />
        <Route path="/live" element={<LivePage />} />
      </Routes>
      <ToastContainer style={{ zIndex: '1000000' }} />
      <RenderIf
        condition={
          user.id && typeof user.birthDate !== 'undefined' && !user.birthDate
        }
      >
        <UpdateBirthDate />
      </RenderIf>
      <WarningModal />
    </>
  );
}
