import { Navigate, Route, Routes } from 'react-router-dom';

import BalanceHistoryPage from "../pages/BalanceHistoryPage";
import BetHistoryPage from "../pages/BetHistoryPage";
import BronUpdatePage from '../pages/BronUpdatePage';
import MyBronTicketsPage from '../pages/MyBronTicketsPage';
import MyTicketsPage from '../pages/MyTicketsPage';
import WinHistoryPage from "../pages/WinHistoryPage";

const PrivateRoutes = () => {
  return (
        <Routes>
            <Route path="/auth/*" element={<Navigate replace to="/" />} />
            <Route path="/my-tickets/*" element={<MyTicketsPage />} />
            <Route path="/my-bron-tickets/*" element={<MyBronTicketsPage />} />
            <Route path="/bron-update/:id" element={<BronUpdatePage />} />
            <Route path="/bet-history" element={<BetHistoryPage />} />
            <Route path="/balance-history" element={<BalanceHistoryPage />} />
            <Route path="/win-history" element={<WinHistoryPage />} />
        </Routes>
  );
};

export default PrivateRoutes;
