import HomeLayout from 'components/layout/HomeLayout';
import PaymentError from 'modules/payment/PaymentError';

const PaymentErrorPage = () => (
  <HomeLayout>
    <PaymentError />
  </HomeLayout>
);

export default PaymentErrorPage;
