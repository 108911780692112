import { useEffect, useState } from 'react';

import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import './Home.css';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    background: '#1e1e1e',
  },
  text: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: '500',
    color: '#FFFFFF',
    maxWidth: '580px',
    textAlign: 'center',
  },
});

const Home = () => {
  const [game, setGame] = useState({});

  const { token } = useSelector((state) => state.auth);
  const { id } = useSelector((state) => state.scan);
  const { gameId } = useSelector((state) => state.game);

  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      const GAME_INIT_URL = token
        ? '/customers/gameInit'
        : '/customers/gameDemo';
      try {
        const { data } = await axios.get(GAME_INIT_URL, {
          params: {
            gameId,
          },
        });
        setGame(data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [token, id, gameId]);

  return (
    <div className="home">
      <Helmet>
        <title>Ana səhifə | Nard</title>
        <meta name="description" content="Ana səhifə | Nard" />
      </Helmet>
      <div className={`iframe__container iframe__container-mobile`}>
        <iframe
          src={game?.gameUrl}
          style={{
            border: 'none',
            width: '100%',
            maxWidth: '1600px',
            height: '100%',
            overflow: 'visible',
            marginTop: '8px',
          }}
          title="External Content"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Home;
