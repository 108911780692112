import BronLayout from 'components/layout/BronLayout';
import PlayList from 'modules/home/pages/PlayList';

const BronPage = () => (
  <BronLayout>
    <PlayList />
  </BronLayout>
);

export default BronPage;
