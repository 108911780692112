import HomeLayout from 'components/layout/HomeLayout';

import {BetHistory} from "../modules/bet-history";

const BronPage = () => (
    <HomeLayout>
        <BetHistory />
    </HomeLayout>
);

export default BronPage;
