import HomeLayout from 'components/layout/HomeLayout';

import Result from '../modules/results';

const ResultPage = () => (
  <HomeLayout>
    <Result />
  </HomeLayout>
);

export default ResultPage;
