import * as React from 'react';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { azAZ } from '@mui/material/locale';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import AlertComponent from 'components/alert';
import RenderIf from 'components/RenderIf';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { AiOutlineEye } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import {createUseStyles} from 'react-jss'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import './styles/style.css';

const theme = createTheme({}, azAZ);

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(2n)': {
    backgroundColor: '#EAF6FF',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headCells = [
  {
    id: 'Circulation.Product.Name',
    label: 'Məhsul adı',
  },
  {
    id: 'ticketNumber',
    label: 'Kupon nömrəsi',
  },
  {
    id: 'Circulation.Code',
    label: 'Tur nömrəsi',
  },
  {
    id: 'price',
    label: 'Cəmi mərc məbləği',
  },
  {
    id: 'Circulation.Product.ColumnPrice',
    label: 'Sütun üzrə mərc məbləği',
  },
  {
    id: 'isPaid',
    label: 'Kuponun statusu',
  },
  {
    id: 'createdDate',
    label: 'Tarix',
  },
  {
    id: 'operations',
    label: 'Əməliyyatlar',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const useStyles = createUseStyles({
    activeHeadCell: {
      borderBottom: '1px solid #24A3FF !important',
      color: '#24A3FF !important',
    },
    actioveDirectionRow: {
      color: '#24A3FF !important',
    },
    TableRow: {
      padding: '0 35px !important',
    },
  });

  const classes = useStyles();
  return (
    <TableHead>
      <TableRow className={classes.TableRow}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={
                orderBy === headCell.id
                  ? `${classes.activeHeadCell} activeHeadCell`
                  : null
              }
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  className={classes.actioveDirectionRow}
                  component="span"
                  sx={visuallyHidden}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  isLoading,
  setIsLoading,
  setShowBarcode,
  setTicketInformation,
}) {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('createdDate');
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [pendingStatus, setPendingStatus] = React.useState({
    label: 'Hamısı',
    value: 'Hamısı',
  });

  const [rows, setRows] = React.useState([]);
  const [skip, setSkip] = React.useState(0);
  const [take, setTake] = React.useState(10);
  const [sortField, setSortField] = React.useState('createdDate');
  const [totalCount, setTotalCount] = React.useState(0);

  const navigate = useNavigate();

  const onGetTicket = (filter = {}) => {
    const {
      pendingStatus: { label },
    } = filter;
    setIsLoading(true);

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }voidTickets/userTickets?Skip=${skip}&Take=${take}${
          label !== 'Hamısı'
            ? `&isPaid=${
                label === 'Gözləmədə'
                  ? false
                  : label === 'Mərc edilmişdir'
                  ? true
                  : ''
              }`
            : ''
        }${sortField ? `&sortField=${sortField}` : ''}&orderBy=${
          order === 'asc'
        }`
      )
      .then((r) => {
        const data = r.data.data.map((i) => ({
          id: i.id,
          name: i.productName,
          ticketNumber: i.ticketNumber,
          circulationNumber: i.circulationNumber,
          price: i.price,
          columnPrice: i.columnPrice,
          isPaid: i.isPaid,
          isExpired: i.isExpired,
          date: i.createdDate,
          customerNumber: i.customerNumber,
        }));
        setRows(data);
        setTotalCount(r.data.count);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    setIsLoading(true);
    onGetTicket({ pendingStatus });
  }, [pendingStatus, skip, take, sortField, order]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setSortField(property);
  };

  const handleChangePage = (e, page) => {
    const skip = page * take;
    setSkip(skip);
    setPage(page);
  };

  const useStyles = createUseStyles({
    TableCell: {
      padding: '14px 35px !important',
    },
    blue: {
      color: '#24A3FF',
    },
    red: {
      color: '#FF0009',
    },
    gray: {
      color: 'rgb(153, 153, 153)',
    },
    success: {
      color: '#1dd419',
    },
    button: {
      background: 'unset',
      '& svg': {
        width: '20px',
        height: '20px',
        color: '#0996ed',
      },
    },
  });

  const classes = useStyles();

  const pendingStatusOptions = [
    { label: 'Hamısı', value: 'Hamısı' },
    { label: 'Gözləmədə', value: false },
    { label: 'Mərc edilmişdir', value: true },
  ];

  function CustomSelect() {
    const pendingStatustHandler = (e) => {
      setPendingStatus(e);
      setSkip(0);
    };

    return (
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="custom-select winning-status">
            <label className="custom-select__label">Kupon statusu</label>
            <Select
              options={pendingStatusOptions}
              defaultValue={{ label: 'Hamısı' }}
              value={pendingStatus}
              onChange={pendingStatustHandler}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%' }} className="custom-table">
      <CustomSelect />
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody sx={{ whiteSpace: 'nowrap' }}>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledTableRow tabIndex={-1} key={row.id}>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.TableCell}
                      align="center"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell padding="none" align="center">
                      {row.ticketNumber}
                      <button
                        onClick={() => {
                          setShowBarcode(true);
                          setTicketInformation({
                            barcode: row.ticketNumber,
                            ticketNumber: row.ticketNumber,
                            ticketId: row.id,
                            customerNumber: row.customerNumber,
                            createdDate: row.date,
                            price: row.price,
                          });
                        }}
                        className={classes.button}
                      >
                        <AiOutlineEye />
                      </button>
                    </TableCell>
                    <TableCell padding="none" align="center">
                      {row.circulationNumber}
                    </TableCell>
                    <TableCell padding="none" align="center">
                      {row.price}
                    </TableCell>
                    <TableCell padding="none" align="center">
                      {row.columnPrice}
                    </TableCell>
                    <TableCell padding="none" align="center">
                      <span
                        className={row.isPaid ? classes.blue : classes.gray}
                      >
                        {row.isPaid ? 'Mərc edilmişdir' : 'Gözləmədə'}
                      </span>
                    </TableCell>
                    <TableCell padding="none" align="center">
                      {format(new Date(row.date), 'dd.MM.yyyy HH:mm:ss')}
                    </TableCell>
                    <TableCell padding="none" align="center">
                      <span
                        className={
                          row.operations === 'Pending'
                            ? classes.gray
                            : row.operations === 'Completed'
                            ? classes.success
                            : ''
                        }
                      >
                        {row.isExpired || row.isPaid ? '-' : 'Düzəliş et'}
                      </span>
                      {row.isPaid || row.isExpired ? (
                        ''
                      ) : (
                        <button
                          className="edit-button"
                          onClick={() => {
                            navigate(`/bron-update/${row.id}`);
                          }}
                        >
                          <FiEdit />
                        </button>
                      )}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
          <RenderIf condition={rows.length}>
            <ThemeProvider theme={theme}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={take}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={(e) => setTake(Number(e.target.value))}
              />
            </ThemeProvider>
          </RenderIf>
          <RenderIf condition={!rows.length && !isLoading}>
            <AlertComponent message="Bron edilmiş biletiniz yoxdur." />
          </RenderIf>
        </TableContainer>
      </Paper>
    </Box>
  );
}
