import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import { actions } from 'modules/auth/_redux/authRedux';
import { actions as scanActions } from 'modules/home/_redux/scanRedux';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

const useStyles = createUseStyles({
  title: {
    fontSize: '32px',
    lineHeight: '42px',
    fontWeight: 500,
  },
  closeButton: {
    width: '36px',
    height: '36px',
    border: 'unset',
    borderRadius: '50%',
    position: 'absolute',
    right: '30px',
    top: '20px',
    background: '#1E1E1E',
    padding: 'unset',

    '& svg': {
      stroke: '#FFFFFF',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
  },
  text: {
    maxWidth: '474px',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  inputContainer: {
    padding: '12px 16px',
    borderRadius: '10px',
    background: '#F5F5F5',
    '& svg': {
      marginRight: '8px',
    },
  },
  input: {
    border: '1px solid transparent',
    background: '#f5f5f5',
    outline: 'unset',
    borderRadius: '8px',
    padding: '16px',
    width: '100%',
    marginBottom: '6px',
  },
  select: {
    border: 'unset',
    background: '#f5f5f5',
    outline: 'unset',
    borderRadius: '8px',
    padding: '16px',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    background: '#00AFF5',
    width: '100%',
  },
  randomButton: {
    background: '#3bd05c40',
    color: '#16CA68',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    width: '100%',
  },
  greenCircle: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: '4px solid #34C759',
  },
  greenCircleFull: {
    backgroundColor: '#34C759',
  },
  agents: {
    width: '100%',
    display: 'flex',
    gap: '12px',
  },
  submit: {
    backgroundColor: '#2098e1',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    textAlign: 'center',
    padding: '10px',
    borderRadius: '30px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    color: '#FFFFFF !important',
    height: '24px !important',
    width: '24px !important',
  },
  hint: {
    color: '#393E46',
    fontSize: '12px',
    fontWeight: '400',
    marginBottom: '12px',
  },
  red: {
    color: 'red',
  },
  inputError: {
    borderColor: 'red',
  },
});

export default function TopupBalanceVoucher({
  setVoucherAmount,
  setShowBalanceInfo,
  onHide,
}) {
  const [agents] = useState([
    { label: '123456', value: '123456' },
    { label: '171717', value: '171717' },
  ]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      voucherNumber: '',
    },
    onSubmit: async ({ voucherNumber }, { resetForm, setSubmitting }) => {
      try {
        setSubmitting(true);
        const { data } = await axios.put('vouchers/consume', {
          voucherNumber,
        });
        setVoucherAmount(data);
        setShowBalanceInfo(true);
        dispatch(actions.getProfile());
        dispatch(scanActions.setRandomId(uuid()));
        resetForm();
        onHide(false);
      } catch (err) {
        toast.error(err.response?.data?.message ?? 'Xəta baş verdi.');
      } finally {
        setSubmitting(false);
        resetForm();
        dispatch(scanActions.resetScanner());
      }
    },
  });

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <div className="w-100 d-flex flex-column gap-2 flex-wrap flex-sm-nowrap">
          <input
            type="text"
            className={`${classes.input} ${
              formik.errors.amount ? classes.inputError : ''
            }`}
            placeholder="Vauçer kod*"
            inputMode="numeric"
            {...formik.getFieldProps('voucherNumber')}
          />
        </div>
        <button
          className={classes.submit}
          type="submit"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <CircularProgress className={classes.progress} />
          ) : (
            'Balansı artır'
          )}
        </button>
      </form>
    </div>
  );
}
