import React from "react";

import {InfoIcon, MultiplyArrow} from "components/icons";
import ReactSelect from "react-select";

import Tooltip from "../../../components/tooltip";
import "./Multiply.css";

const options = [
    {
        label: "1X",
        value: 1
    },
    {
        label: "2X",
        value: 2
    },
    {
        label: "3X",
        value: 3
    },
    {
        label: "4X",
        value: 4
    },
    {
        label: "5X",
        value: 5
    },
    {
        label: "6X",
        value: 6
    },
    {
        label: "7X",
        value: 7
    },
    {
        label: "8X",
        value: 8
    },
    {
        label: "9X",
        value: 9
    },
    {
        label: "10X",
        value: 10
    }
];

const customStyles = {
    control: (baseStyles) => ({
        ...baseStyles,
        borderRadius: "8px",
        background: "#ffffffe6",
        border: "unset",
        boxShadow: "unset",
        borderColor: "#999999",
        minHeight: "unset",
        '&:hover': {
            boxShadow: "unset",
        }
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: "0 6px"
    }),
    dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        padding: "6px"
    }),
    indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        margin: "unset",
    }),
    indicatorsContainer: (baseStyles) => ({
        ...baseStyles,
        '& svg': {
            margin: "6px"
        }
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: "8px",
        background: "#FFFFFF",
        boxShadow: "0 4px 8px 0 rgba(9, 30, 66, 0.20)",
        overflow: "hidden"
    }),
    menuList: (baseStyles) => ({
        ...baseStyles,
        padding: "unset",
        maxHeight: "unset"
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: state.isFocused ? "#FF0009" : "#FFFFFF",
        color: state.isFocused ? "#FFFFFF" : "#323232"
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        fontSize: "14px",
        fontWeight: "600",
        color: "#FF0009"
    })
};

const tooltipData = [{
    id: 1,
    title: "Misli nədir?",
    description: "Əgər siz bir neçə qazananlar siyahısında öz uduş məbləğinizi artırmaq istəyirsinizsə, 1-10 aralığnda misli seçərək öz hovuz və ya cekpot qazancınızı artıra bilərsiniz. Necə? Hovuz və ya Cekpot məbləği birdən çox oyunçu tərəfindən qazanıldığı halda, uduş məbləği misli saylarının cəminə bölünərək hər oyunçuya müvafiq misli ilə təqdim olunur.\n" +
        " Məsələn, 10 000AZN hovuz məbləği üçün siz 2X seçərək, başqa bir oyunçu 1X, daha başqa bir oyunçu isə 1X seçərək qalib olmuşdur. Bu zaman 10 000 AZN 4-ə(2X+1X+1X) bölünür və uduş hər oyunçunun misli nisbətinə vurularaq təqdim edilir. Yəni bu halda siz (10 000/4)*2= 5000AZN, digər 1X misli seçən oyunçuların hər biri isə (10 000/4)*1= 2 500AZN qazanır.\n" +
        "\n" +
        "Qeyd: Bu zaman mərc məbləği də seçilən misli nisbətinə vurularaq hesablanır."
}]


const MultiplySelect = ({onChange, value}) => {
    return (
        <div className="multiply__container">
            <div className="multiply__title">
                <span>Misli</span>
                <Tooltip id="my-tooltip-2" data={tooltipData}>
                    <InfoIcon/>
                </Tooltip>

            </div>
            <ReactSelect
                styles={customStyles}
                options={options}
                menuPlacement="top"
                components={{
                    DropdownIndicator: MultiplyArrow
                }}
                value={value}
                isSearchable={false}
                onChange={(newValue) => onChange(newValue)}
            />
        </div>
    )
}

export default MultiplySelect;