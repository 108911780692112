import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
        >
            <rect width="52" height="52" fill="#B59F6B" rx="26"></rect>
            <path
                fill="#E8EAED"
                d="M18.948 37.334v-4.282h-4.282v-2h6.282v6.282h-2zm12.115 0v-6.282h6.282v2h-4.282v4.282h-2zM14.666 20.949v-2h4.282v-4.282h2v6.282h-6.282zm16.397 0v-6.282h2v4.282h4.282v2h-6.282z"
            ></path>
        </svg>
    );
}

export default Icon;