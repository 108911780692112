import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            fill="none"
            viewBox="0 0 52 52"
        >
            <rect width="52" height="52" fill="#B59F6B" rx="26"></rect>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15.334 20.666V18a2.666 2.666 0 012.667-2.667h2.666m-5.333 16V34a2.667 2.667 0 002.667 2.666h2.666m10.667-21.333h2.667A2.667 2.667 0 0136.667 18v2.666m-5.333 16h2.667A2.667 2.667 0 0036.667 34v-2.667"
            ></path>
        </svg>
    );
}

export default Icon;
